import React from "react";
import Logo from "@/icons/Logo";

import s from './Logotype.module.scss'

export default function Logotype() {
  return (
    <div className={s.logotype}>
        <div className={s.logotype__icon}><Logo/></div>
    </div>
  )
}