import React from "react";

type Props = {};

export default function Archive({}: Props) {
    return (
        <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <clipPath id="clip318_13090">
                    <rect id="Variant=Archive" width="24.000000" height="24.000000" fill="white" fillOpacity="0"/>
                </clipPath>
            </defs>
            <g clipPath="url(#clip318_13090)">
                <g opacity="0.100000">
                    <path id="path"
                          d="M12 2C16.793 2 19.1896 2 20.6785 3.28137C21.4487 3.94421 21.8206 4.81592 22 6.07397L22 9L2 9L2 6.07397C2.17944 4.81592 2.55127 3.94421 3.32153 3.28137C4.81042 2 7.20703 2 12 2Z"
                          fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
                </g>
                <path id="path"
                      d="M2 14C2 11.1997 2 9.79956 2.54492 8.7301C3.02429 7.78918 3.78918 7.02429 4.7301 6.54492C5.79956 6 7.19971 6 10 6L14 6C16.8003 6 18.2004 6 19.27 6.54492C20.2108 7.02429 20.9757 7.78918 21.455 8.7301C22 9.79956 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22L10 22C7.19971 22 5.79956 22 4.7301 21.455C3.78918 20.9757 3.02429 20.2108 2.54492 19.27C2 18.2004 2 16.8003 2 14ZM12.5303 10.4697C12.3896 10.329 12.1989 10.25 12 10.25C11.8011 10.25 11.6104 10.329 11.4697 10.4697L8.96973 12.9697C8.67676 13.2626 8.67676 13.7374 8.96973 14.0303C9.26257 14.3232 9.73743 14.3232 10.0303 14.0303L11.25 12.8107L11.25 17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17L12.75 12.8107L13.9697 14.0303C14.2626 14.3232 14.7374 14.3232 15.0303 14.0303C15.3232 13.7374 15.3232 13.2626 15.0303 12.9697L12.5303 10.4697Z"
                      fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
            </g>
        </svg>

    );
}

