import {useMutation} from "@tanstack/react-query";
import {queryClient} from "@/App";
import {TaskDto} from "@/api/tasks/dto/task.dto";
import {CreateTaskDto} from "@/api/tasks/dto/create.task.dto";
import tasksMethods from "@/api/tasks/TasksMethods";

const useMutationTaskCreate = () => {
    return useMutation<TaskDto, Error, { body: CreateTaskDto }>({
        mutationFn: async ({body}) => {
            return await tasksMethods.create(body);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["tasks"]});
            queryClient.invalidateQueries({queryKey: ["statuses-task"]});
        },
    })
};

export default useMutationTaskCreate;
