import store from "@/store";
import axios, {AxiosRequestConfig} from "axios";

export interface QueryPagination {
    page?: number;
    limit?: number;
    sortBy?: string[];
    search?: string;
    searchBy?: string;
    filter?: FilterType;
    select?: string[];
    [key: string]: string | string[] | number | undefined | FilterType;
}

interface FilterType {
    [key: string]: string | string[];
}


interface Meta {
    totalItems: number ,
    itemsPerPage: number ,
    totalPages: number ,
    currentPage: number ,
}


export interface Pagination<T> {
    data: T[];
    meta: Meta,
    links: {}
}

export default class Api {
    protected address: string = process.env.REACT_APP_API_ENDPOINT || "ENV_APP_API_ENDPOINT";
    protected token: string | null = null

    constructor() {
        const {auth: {token}} = store.getState()
        if (token)
            this.token = token
    }

    request<RES, REQ>(method: string, config: AxiosRequestConfig<REQ>) {
        return axios<RES>(this.address + method, config);
    }
}
