import React, {ReactNode} from "react";
import classNames from "classnames";

import s from './Tag.module.scss'
import Add from "@/icons/Add";
import Close from "@/icons/Close";

type TagType = 'default' | 'taskCreation';

type Props = {
    children: ReactNode
    onDelete?: () => void,
    type?: TagType
};

export default function Tag({children, onDelete, type = 'default'}: Props) {

    const tagClass = classNames(s.tag, {
        [s.default]: type === 'default',
        [s.taskCreation]: type === 'taskCreation',
    })

    return (
        <div className={tagClass}>
            {children}
            {onDelete &&
                <button className={s.tag_deleteButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete();
                        }}>
                    <Close/>
                </button>
            }
        </div>
    );
}
