import {useMutation} from "@tanstack/react-query";
import statusesMethods from "@/api/statuses/StatusesMethods";
import {CreateStatusesDto} from "@/api/statuses/dto/create.statuses.dto";
import {queryClient} from "@/App";
import {StatusesShortDto} from "@/api/statuses/dto/statuses-short.dto";

const useMutationStatusesCreate = () => {
    return useMutation<StatusesShortDto, Error, { body: CreateStatusesDto }>({
        mutationFn: async ({body}) => {
            return await statusesMethods.create(body);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({queryKey: ["statuses-task"]});
            queryClient.invalidateQueries({queryKey: ["statuses"]});
        },
    })
};

export default useMutationStatusesCreate;
