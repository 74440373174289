import React, {useEffect, useState} from 'react'
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";

import PageHeader from "@/components/PageHeader";
import KanbanRow from "@/pages/DashboardPage/components/KanbanRow";

import useMutationTaskUpdateIndexes from "@hooks/mutations/tasks/useMutationTaskUpdateIndexes";
import UseFetchStatusesTasks from "@hooks/query/statuses/useFetchStatusesTasks";
import useMutationTaskUpdate from "@hooks/mutations/tasks/useMutationTaskUpdate";
import {StatusesDto} from "@/api/statuses/dto/statuses.dto";
import {TaskDto} from "@/api/tasks/dto/task.dto";

import Toasts from "@/utils/Toasts";

import s from './DashboardPage.module.scss'
import {CreateTaskForm} from "@/components/forms/CreateTaskForm";
import {Modal} from "@/components/Modal";

export default function DashboardPage() {
    const {t} = useTranslation();

    const {data} = UseFetchStatusesTasks()
    const [columns, setColumns] = useState<StatusesDto[]>([]);
    const updateTaskUpdateIndexes = useMutationTaskUpdateIndexes()
    const updateTaskUpdate = useMutationTaskUpdate()

    useEffect(() => {
        if (data) {
            setColumns(data)
        }
    }, [data]);


    const handleDragEnd = (result: DropResult) => {
        const {destination, source, draggableId} = result;
        if (!destination || !draggableId) return;

        const updatedColumns = [...columns];

        const columnToUpdate = updatedColumns.find((column) => `${column.title}-${column.id}` === source.droppableId);
        const destinationColumn = updatedColumns.find((column) => `${column.title}-${column.id}` === destination.droppableId);

        if (columnToUpdate && destinationColumn) {
            if (source.droppableId !== destination.droppableId) {
                const sourceColumnIndex = updatedColumns.findIndex((column) => (`${column.title}-${column.id}` === source.droppableId));
                const destinationColumnIndex = updatedColumns.findIndex((column) => `${column.title}-${column.id}` === destination.droppableId);

                if (sourceColumnIndex !== -1 && destinationColumnIndex !== -1) {
                    const [removedTask] = updatedColumns[sourceColumnIndex].tasks.splice(source.index, 1);
                    updatedColumns[destinationColumnIndex].tasks.splice(destination.index, 0, removedTask);

                    updateTaskUpdate.mutateAsync({
                        id: parseInt(draggableId),
                        body: {
                            statuses: destinationColumn.id
                        }
                    }).catch(() => Toasts.error(t('alerts.tasks.update.error')))
                }
            } else {
                const [removedTask] = columnToUpdate.tasks.splice(source.index, 1);
                columnToUpdate.tasks.splice(destination.index, 0, removedTask);
            }
        }

        setColumns(updatedColumns.map((status) => {
            status.tasks.map((task, index) => {
                task.index = index * -1
                return task
            })

            return status
        }));

        const tasks: TaskDto[] = []

        updatedColumns.forEach((_item) => {
            _item.tasks.forEach((task) => {
                tasks.push(task)
            })
        })

        if (tasks && tasks.length > 0) {
            updateTaskUpdateIndexes.mutateAsync({
                body: tasks.map((_item) => ({
                    id: _item.id,
                    index: _item.index
                }))
            }).catch(() => Toasts.error(t('alerts.tasks.update.error')))
        }
    };

    return (
        <div className={s.container}>
            <PageHeader title="pages.dashboard"/>
            <DragDropContext onDragEnd={handleDragEnd}>
                <div className={s.container__content}>
                    {columns.map((column) => (
                        <KanbanRow key={column.id} item={column}/>
                    ))}
                </div>
            </DragDropContext>

            <Modal name={`CreateTaskModal`}>
                <CreateTaskForm/>
            </Modal>
        </div>
    );
}
