import {useQuery} from "@tanstack/react-query";
import StatusesMethods from "@/api/statuses/StatusesMethods";
import {StatusesDto} from "@/api/statuses/dto/statuses.dto";

const useFetchStatusesTask = () => {
    return useQuery<StatusesDto[], null>({
        queryKey: ["statuses-task"],
        queryFn: async () => {
            return StatusesMethods.getTask();
        },
    });
};

export default useFetchStatusesTask;
