import React from "react";

export default function Drag(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="clip93_4832">
                    <rect id="Variant=Drag" width="24.000000" height="24.000000" fill="white" fillOpacity="0"/>
                </clipPath>
            </defs>
            <rect id="Variant=Drag" width="24.000000" height="24.000000" fill="#FFFFFF" fillOpacity="0"/>
            <g clipPath="url(#clip93_4832)">
                <path id="path"
                      d="M8.2002 16.25C9.41309 16.25 10.3999 17.2031 10.3999 18.375C10.3999 19.5469 9.41309 20.5 8.2002 20.5C6.98682 20.5 6 19.5469 6 18.375C6 17.2031 6.98682 16.25 8.2002 16.25ZM14.7998 16.25C16.0132 16.25 17 17.2031 17 18.375C17 19.5469 16.0132 20.5 14.7998 20.5C13.5869 20.5 12.6001 19.5469 12.6001 18.375C12.6001 17.2031 13.5869 16.25 14.7998 16.25ZM8.2002 9.875C9.41309 9.875 10.3999 10.8281 10.3999 12C10.3999 13.1719 9.41309 14.125 8.2002 14.125C6.98682 14.125 6 13.1719 6 12C6 10.8281 6.98682 9.875 8.2002 9.875ZM14.7998 9.875C16.0132 9.875 17 10.8281 17 12C17 13.1719 16.0132 14.125 14.7998 14.125C13.5869 14.125 12.6001 13.1719 12.6001 12C12.6001 10.8281 13.5869 9.875 14.7998 9.875ZM8.2002 3.5C9.41309 3.5 10.3999 4.45312 10.3999 5.625C10.3999 6.79688 9.41309 7.75 8.2002 7.75C6.98682 7.75 6 6.79688 6 5.625C6 4.45312 6.98682 3.5 8.2002 3.5ZM14.7998 3.5C16.0132 3.5 17 4.45312 17 5.625C17 6.79688 16.0132 7.75 14.7998 7.75C13.5869 7.75 12.6001 6.79688 12.6001 5.625C12.6001 4.45312 13.5869 3.5 14.7998 3.5Z"
                      fill="#FFFFFF" fillOpacity="0.600000" fillRule="evenodd"/>
            </g>
        </svg>
    );
}
