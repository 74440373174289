import React from "react";

interface Props extends React.SVGAttributes<HTMLOrSVGElement> {

}

export default function Search({...props }: Props) {
    return (
        <svg {...props} width="16.000000" height="16.000000" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <desc>
                Created with Pixso.
            </desc>
            <defs>
                <clipPath id="clip323_37798">
                    <rect id="Variant=Search" width="16.000000" height="16.000000" fill="white" fill-opacity="0"/>
                </clipPath>
            </defs>
            <g clip-path="url(#clip323_37798)">
                <path id="path" d="M12.66 7.33C12.66 10.27 10.27 12.66 7.33 12.66C4.38 12.66 2 10.27 2 7.33C2 4.38 4.38 2 7.33 2C10.27 2 12.66 4.38 12.66 7.33ZM10.5 11.72L10.47 11.72C10.12 11.37 10.12 10.81 10.48 10.45C10.83 10.1 11.39 10.1 11.75 10.46L11.75 10.48L10.5 11.72ZM14.61 13.36L14.63 13.36C14.99 13.72 14.99 14.28 14.63 14.63C14.27 14.99 13.71 14.99 13.36 14.63L13.36 14.6L14.61 13.36Z" fill="#000000" fill-opacity="0" fill-rule="nonzero"/>
                <path id="path" d="M11.11 11.09L14 14M7.33 12.66C4.38 12.66 2 10.27 2 7.33C2 4.38 4.38 2 7.33 2C10.27 2 12.66 4.38 12.66 7.33C12.66 10.27 10.27 12.66 7.33 12.66Z" stroke="#FFFFFF" stroke-opacity="1.000000" stroke-width="1.800000" stroke-linejoin="round" stroke-linecap="round"/>
            </g>
        </svg>

    );
}

