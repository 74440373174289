import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserDto} from "@/api/users/dto/user.dto";

interface ProfileState {
    profile: UserDto | undefined
}

const initialState: ProfileState = {
    profile: undefined,
}

export const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action: PayloadAction<UserDto | undefined>) => {
            state.profile = action.payload
        }
    },
})

export const {setProfile} = ProfileSlice.actions

export default ProfileSlice.reducer
