import React, {useCallback, useEffect, useState} from 'react';
import Play from "@/icons/Play";
import Stop from "@/icons/Stop";
import s from './Timekeeper.module.scss';
import useMutationTimerStart from "@hooks/mutations/timer/useMutationTimerStart";
import useMutationTimerStop from "@hooks/mutations/timer/useMutationTimerStop";
import {useAppDispatch, useAppSelector} from "@hooks/store";
import {setRunning, setTimer} from "@store/reducers/timerReducer";
import {DateTime, Duration} from "luxon";

type TimekeeperProps = {
    seconds?: number,
    taskId?: number
}

const titlePage = document.title

export default function Timekeeper({seconds = 0, taskId}: TimekeeperProps) {
    const [timeInSeconds, setTimeInSeconds] = useState<number>(seconds);
    const dispatch = useAppDispatch();
    const {timer, isRunning} = useAppSelector((state) => state.timer)

    const mutationTimerStart = useMutationTimerStart();
    const mutationTimerStop = useMutationTimerStop();

    const getTime = useCallback(() => {
        if (taskId && timer && timer.task.id === taskId && isRunning) {
            return DateTime.now().toUTC().diff(DateTime.fromISO(timer.start_date).toUTC()).toFormat("hh:mm:ss");
        } else {
            return Duration.fromObject({seconds: timeInSeconds}).toFormat("hh:mm:ss")
        }
    }, [timeInSeconds, timer, isRunning, taskId]);

    useEffect(() => {
        if (taskId && timer && timer.task.id === taskId) {
            let interval: NodeJS.Timeout;

            if (isRunning) {
                interval = setInterval(() => {
                    setTimeInSeconds((prevTime) => prevTime + 1);
                    document.title = `${getTime()} - ${(timer && timer.task && timer.task.title.length > 20) ? timer.task.title.slice(0, 17) + '...' : timer.task.title}`
                }, 1000);
            }

            return () => {
                clearInterval(interval);
            };
        }
    }, [timer, isRunning]);

    const playTimer = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        if (taskId) {
            const timerData = await mutationTimerStart.mutateAsync({taskId: taskId})
            dispatch(setTimer(timerData))
            dispatch(setRunning(true))
        }
    }, [timer, isRunning]);

    const stopTimer = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        await mutationTimerStop.mutateAsync()
        dispatch(setTimer(undefined))
        dispatch(setRunning(false))
        document.title = titlePage
    }, [timer, isRunning]);

    return (
        <div className={s.container}>
            <div className={s.container__content}>
                {(taskId) ?
                    <div className={s.container__content__taskControl}>
                        {taskId && timer && timer.task.id === taskId && isRunning ? (
                            <button type="button" className={s.container__content__taskControl__taskControlButton}
                                    onClick={stopTimer}>
                                <Stop style={{width: "24px", height: "24px"}}/>
                            </button>
                        ) : (
                            taskId &&
                            <button type="button" className={s.container__content__taskControl__taskControlButton}
                                    onClick={playTimer}>
                                <Play style={{width: "24px", height: "24px"}}/>
                            </button>
                        )
                        }
                    </div>: undefined}
                <div className={s.container__content__taskTimer}>
                    {getTime()}
                </div>
            </div>
        </div>
    );
}
