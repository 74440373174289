import React from 'react';
import s from './SearchInput.module.scss';
import Search from "@/icons/Search";

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ value, onChange, ...props }) => {
    return (
        <div className={s.searchInput}>
            <input
                className={s.searchInput_input}
                {...props}
                value={value}
                onChange={onChange}
                type="text"
                placeholder="Поиск"
            />
            <button className={s.searchInput_button}>
                <Search className={s.searchInput_button_icon} />
            </button>
        </div>
    );
};

export default SearchInput;
