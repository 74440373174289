import {useTranslation} from "react-i18next";

import React from "react";
import Input from "@/UI/Input";
import Button from "@/UI/Button";
import {useForm} from "react-hook-form";
import {IConfirmForm} from "@/utils/Forms";
import {yupResolver} from "@hookform/resolvers/yup";
import {SchemaConfirm} from "@/utils/config/yupShemes";
import {useParams} from "react-router";
import Toasts from "@/utils/Toasts";
import UsersMethods from "@/api/users/UsersMethods";
import {useNavigate} from "react-router-dom";

export default function ConfirmForm() {
    const {t} = useTranslation();
    const {id, hash} = useParams();
    const navigation = useNavigate()

    const {
        register,
        formState: {errors},
        handleSubmit
    } = useForm<IConfirmForm>({
        resolver: yupResolver(SchemaConfirm),
        mode: 'onBlur',
    });

    const submit = async (data: IConfirmForm) => {
        try {
            if (id && hash) {
                await UsersMethods.confirmById(parseInt(id), {hash: hash, password: data.password})
                Toasts.success(t('alerts.confirm.success'))
                navigation("/login", {replace: true})
            }
        } catch (e) {
            Toasts.error(t('alerts.confirm.error'))
        }
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Input type='password' label={t('inputs.label.password')}
                   placeholder={t('inputs.placeholder.password')}
                   error={errors['password']?.message ? t(errors['password']?.message) : undefined}
                   {...register('password', {required: true})}/>

            <Input type='password' label={t('inputs.label.confirmPassword')}
                   placeholder={t('inputs.placeholder.confirmPassword')}
                   error={errors['confirmPassword']?.message ? t(errors['confirmPassword']?.message) : undefined}
                   {...register('confirmPassword', {required: true})}/>
            <div>
                <Button type="submit">{t('buttonActions.confirm')}</Button>
            </div>
        </form>
    )
}