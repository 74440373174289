import Api from "@/api/Api";
import {CreateStatusesDto} from "@/api/statuses/dto/create.statuses.dto";
import {StatusesShortDto} from "@/api/statuses/dto/statuses-short.dto";
import {StatusesDto} from "@/api/statuses/dto/statuses.dto";
import {UpdateStatusesDto} from "@/api/statuses/dto/update.statuses.dto";
import {UpdateIndexStatusesDto} from "@/api/statuses/dto/update-index.statuses.dto";

class StatusesMethods extends Api {
    async create(body: CreateStatusesDto) {
        const {data} = await this.request<StatusesShortDto, CreateStatusesDto>(`/statuses`, {
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async get() {
        const {data} = await this.request<StatusesShortDto[], null>('/statuses', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async getTask() {
        const {data} = await this.request<StatusesDto[], null>('/statuses/tasks/all', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async getById(id: number) {
        const {data} = await this.request<StatusesShortDto, null>(`/statuses/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async updateById(id: number, body: UpdateStatusesDto) {
        const {data} = await this.request<StatusesShortDto, UpdateStatusesDto>(`/statuses/${id}`, {
            method: 'PUT',
            data: body,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async updateIndexes(body: UpdateIndexStatusesDto[]) {
        const {data} = await this.request<void, UpdateIndexStatusesDto[]>(`/statuses/update/indexes`, {
            method: 'PUT',
            data: body,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async removeById(id: number) {
        const {data} = await this.request<void, null>(`/statuses/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }
}

export default new StatusesMethods()