import React from "react";

type Props = {};

export default function Error({}: Props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip26_4230">
          <rect id="Variant=Error" width="24.000000" height="24.000000" fill="white" fillOpacity="0"/>
        </clipPath>
      </defs>
      <rect id="Variant=Error" width="24.000000" height="24.000000" fill="#FFFFFF" fillOpacity="0"/>
      <g clipPath="url(#clip26_4230)">
        <g opacity="0.500000">
          <path id="path" d="M3 10.4165C3 7.21924 3 5.62012 3.37744 5.08252C3.75488 4.54443 5.2583 4.02979 8.26514 3.00098L8.83789 2.80469C10.4048 2.26807 11.1885 2 12 2C12.8115 2 13.5952 2.26807 15.1621 2.80469L15.7349 3.00098C18.7417 4.02979 20.2451 4.54443 20.6226 5.08252C21 5.62012 21 7.21924 21 10.4165L21 11.9912C21 17.6294 16.7612 20.3657 14.1016 21.5273C13.3799 21.8423 13.0195 22 12 22C10.9805 22 10.6201 21.8423 9.89844 21.5273C7.23877 20.3657 3 17.6294 3 11.9912L3 10.4165Z" fill="#FFFFFF" fillOpacity="1.000000" fillRule="nonzero"/>
        </g>
        <path id="path" d="M12 7.25C12.4141 7.25 12.75 7.58594 12.75 8L12.75 12C12.75 12.4141 12.4141 12.75 12 12.75C11.5859 12.75 11.25 12.4141 11.25 12L11.25 8C11.25 7.58594 11.5859 7.25 12 7.25Z" fill="#FFFFFF" fillOpacity="1.000000" fillRule="nonzero"/>
        <path id="path" d="M12 16C12.5522 16 13 15.5522 13 15C13 14.4478 12.5522 14 12 14C11.4478 14 11 14.4478 11 15C11 15.5522 11.4478 16 12 16Z" fill="#FFFFFF" fillOpacity="1.000000" fillRule="nonzero"/>
      </g>
    </svg>

  );
}

