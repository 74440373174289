import {useQuery} from "@tanstack/react-query";
import {UserDto} from "@/api/users/dto/user.dto";
import ProfileMethods from "@/api/profile/ProfileMethods";

const useFetchProfile = () => {
    return useQuery<UserDto, null>({
        queryKey: ["profile"],
        queryFn: async () => {
            return ProfileMethods.get();
        },
    });
};

export default useFetchProfile;
