import {useMutation} from "@tanstack/react-query";
import {queryClient} from "@/App";
import {TaskDto} from "@/api/tasks/dto/task.dto";
import tasksMethods from "@/api/tasks/TasksMethods";
import {UpdateTaskDto} from "@/api/tasks/dto/update.task.dto";
import { CreateUpdateTaskDto } from "@/api/tasks/dto/create-update.task.dto";

const useMutationTaskUpdate = () => {
    return useMutation<TaskDto, Error, { id: number, body: CreateUpdateTaskDto | UpdateTaskDto }>({
        mutationFn: async ({id, body}) => {
            return await tasksMethods.updateById(id, body);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["tasks"]});
            queryClient.invalidateQueries({queryKey: ["statuses-task"]});
        },
    })
};

export default useMutationTaskUpdate;
