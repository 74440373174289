import {useMutation} from "@tanstack/react-query";
import {queryClient} from "@/App";
import usersMethods from "@/api/users/UsersMethods";

const useMutationUserRemove = () => {
    return useMutation<void, Error, { id: number }>({
        mutationFn: async ({id}) => {
            return await usersMethods.removeById(id);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["users"]});
        },
    })
};

export default useMutationUserRemove;
