import React from "react";

type Props = {};

export default function Exit({ }: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M15.9996 2H14.9996C12.1712 2 10.7574 2.00023 9.87868 2.87891C9 3.75759 9 5.1718 9 8.00023V16.0002C9 18.8287 9 20.2429 9.87868 21.1215C10.7572 22 12.1704 22 14.9974 22H14.9996H15.9996C18.828 22 20.2422 22 21.1209 21.1213C21.9996 20.2426 21.9996 18.8284 21.9996 16V8C21.9996 5.17159 21.9996 3.75736 21.1209 2.87868C20.2422 2 18.828 2 15.9996 2Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H4.02744L5.98809 9.56946C6.30259 9.29989 6.33901 8.82642 6.06944 8.51192C5.79988 8.19743 5.3264 8.16101 5.01191 8.43057L1.51191 11.4305C1.34567 11.573 1.25 11.781 1.25 12C1.25 12.2189 1.34567 12.4269 1.51191 12.5694L5.01191 15.5694C5.3264 15.839 5.79988 15.8026 6.06944 15.4881C6.33901 15.1736 6.30259 14.7001 5.98809 14.4305L4.02744 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12Z" fill="white" />
        </svg>
    );
}

