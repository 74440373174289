import React from 'react';
import useFetchTasks from "@hooks/query/tasks/useFetchTasks";

import s from './ScheduleTasks.module.scss'
import TaskItem from "@/components/ScheduleTasks/components/TaskItem";

import {DateTime} from "luxon";
import {TabList, TabPanel, Tabs} from 'react-tabs';
import CustomTab from "@/components/Tabs/CustomTab";

export default function ScheduleTasks() {
    const {data: tasksToday} = useFetchTasks({
        page: 1,
        limit: 10,
        sortBy: [
            'created_at:DESC'
        ],
        ['filter.start_date']: `$btw:${DateTime.now().startOf('day').toISO()},${DateTime.now().endOf('day').toISO()}`
    })
    const {data: tasksTomorrow} = useFetchTasks({
        page: 1,
        limit: 10,
        sortBy: [
            'created_at:DESC'
        ],
        ['filter.start_date']: `$btw:${DateTime.now().plus({day: 1}).startOf('day').toISO()},${DateTime.now().plus({day: 1}).endOf('day').toISO()}`
    })

    return (
        <div className={s.container}>
            <Tabs className={s.container__tabs}>
                <TabList className={s.container__header}>
                    <CustomTab>Today</CustomTab>
                    <CustomTab>Tomorrow</CustomTab>
                </TabList>
                <TabPanel>
                    <div className={s.container__tasks}>
                        {tasksToday?.data.map((task) => (
                            <TaskItem key={task.id} task={task}/>
                        ))}
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={s.container__tasks}>
                        {tasksTomorrow?.data.map((task) => (
                            <TaskItem key={task.id} task={task}/>
                        ))}
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    )
}
