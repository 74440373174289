import React from "react";

type Props = {};

export default function Kanban({}: Props) {
    return (
        <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <desc>
                Created with Pixso.
            </desc>
            <defs>
                <clipPath id="clip323_10579">
                    <rect id="Variant=Kanban" width="24.000000" height="24.000000" fill="white" fill-opacity="0"/>
                </clipPath>
            </defs>
            <g clip-path="url(#clip323_10579)">
                <path id="path"
                      d="M5.84619 8C5.84619 5.17151 5.84619 3.75732 6.74744 2.87866C7.64856 2 9.09912 2 12 2C14.901 2 16.3516 2 17.2527 2.87866C18.1539 3.75732 18.1539 5.17151 18.1539 8L18.1539 16C18.1539 18.8284 18.1539 20.2426 17.2527 21.1213C16.3516 22 14.901 22 12 22C9.09912 22 7.64856 22 6.74744 21.1213C5.84619 20.2426 5.84619 18.8284 5.84619 16L5.84619 8Z"
                      fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
                <g opacity="0.200000">
                    <path id="path"
                          d="M2.76929 3.75C3.19409 3.75 3.53845 4.08582 3.53845 4.5L3.53845 19.5C3.53845 19.9142 3.19409 20.25 2.76929 20.25C2.34436 20.25 2 19.9142 2 19.5L2 4.5C2 4.08582 2.34436 3.75 2.76929 3.75ZM21.2308 3.75C21.6556 3.75 22 4.08582 22 4.5L22 19.5C22 19.9142 21.6556 20.25 21.2308 20.25C20.8059 20.25 20.4615 19.9142 20.4615 19.5L20.4615 4.5C20.4615 4.08582 20.8059 3.75 21.2308 3.75Z"
                          fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd"/>
                </g>
            </g>
        </svg>
    );
}

