import React, {useContext} from 'react';
import {Draggable} from "react-beautiful-dnd";

import Badge from "@/UI/Badge";

import Tag from "@/UI/Tag";
import Timekeeper from "@/components/Timekeeper";
import {TaskDto} from "@/api/tasks/dto/task.dto";

import s from './KanbanItem.module.scss'
import {UpdateTaskForm} from '@/components/forms/UpdateTaskForm';
import {ModalContext} from '@/providers/ModalProvider';
import {StatusesShortDto} from '@/api/statuses/dto/statuses-short.dto';
import {Modal} from '@/components/Modal';
import Ripple from "@/components/Ripple";
import DeadlineNotification from "@/pages/DashboardPage/components/KanbanItem/components/DeadlineNotification";
import classNames from "classnames";
import Avatar from "@/UI/Avatar";
import {getDeadlineStyle} from "@/utils/deadlineNotificator";

interface KanbanItemProps {
    task: TaskDto;
    index: number;
    status: StatusesShortDto
}

export default function KanbanItem({task, index, status}: KanbanItemProps) {

    const {open} = useContext(ModalContext)


    return (
        <>
            <Draggable draggableId={`${task.id}`} key={task.id} index={index}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classNames({
                            [s.kanbanItem]: true,
                            [getDeadlineStyle(task.deadline)]: true
                        })}
                    >
                        <div className={s.kanbanItem__content} onClick={(e) => {
                            open({key: `UpdateTaskModal_${task.id}`})
                        }}>
                            <div className={s.kanbanItem__content__heading}>{task.title}</div>
                            <div className={s.kanbanItem__content__description}>
                                {task.description}
                            </div>
                            <div className={s.kanbanItem__content__footer}>
                                <div className={s.kanbanItem__content__footer__timeKeeper}>
                                    <Timekeeper taskId={task.id} seconds={task.timer?.total_seconds || 0}/>
                                </div>
                                <div className={s.kanbanItem__content__footer__avatarGallery}>
                                    {task.responsible && task.responsible.map((user, i) => (
                                        <div key={i} className={s.kanbanItem__content__footer__avatarGallery__img}>
                                            <Avatar name={user.name} picture={user.picture}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={s.kanbanItem__content__badges}>
                                <div className={s.kanbanItem__content__badges__tags}>
                                    {task.tags && task.tags.map((tag, i) => (
                                        <Tag key={i}>{tag}</Tag>
                                    ))}
                                </div>
                                <div className={s.kanbanItem__content__badges__priority}>
                                    <Badge priority={task.priority}>{task.priority.toLowerCase()}</Badge>
                                </div>
                            </div>
                            {task.deadline && getDeadlineStyle(task.deadline) &&
                                <div className={s.kanbanItem__content__deadline}>
                                    <DeadlineNotification taskDeadline={task.deadline}
                                                          deadlineStyle={getDeadlineStyle(task.deadline)}/>
                                </div>
                            }
                            <Ripple duration={850} color="#ffffff"/>
                        </div>
                    </div>
                )}
            </Draggable>
            <Modal name={`UpdateTaskModal_${task.id}`}>
                <UpdateTaskForm task={task} status={status}/>
            </Modal>
        </>
    );
}
