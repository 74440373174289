import {useTranslation} from "react-i18next";
import WrapperForm from "@/components/forms/WrapperForm/WrapperForm";
import LoginForm from "@/components/forms/LoginForm";

export default function LoginPage() {
  const {t} = useTranslation();

  return (
    <WrapperForm title={t('forms.login')}>
      <LoginForm/>
    </WrapperForm>
  )
}