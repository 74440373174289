import {useMutation} from "@tanstack/react-query";
import {queryClient} from "@/App";
import usersMethods from "@/api/users/UsersMethods";
import {UserDto} from "@/api/users/dto/user.dto";
import {UpdateDataUsersDto} from "@/api/users/dto/update.user.dto";

const useMutationUserUpdate = () => {
    return useMutation<UserDto, Error, { id: number, body: UpdateDataUsersDto }>({
        mutationFn: async ({id, body}) => {
            return await usersMethods.updateById(id, body);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["users"]});
        },
    })
};

export default useMutationUserUpdate;
