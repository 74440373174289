import React from "react";

// eslint-disable-next-line react/require-default-props
export default function Add(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
            <defs>
                <clipPath id="clip1_868">
                    <rect id="Variant=Add" width="24.000000" height="24.000000" fill="white" fill-opacity="0"/>
                </clipPath>
            </defs>
            <g clip-path="url(#clip1_868)">
                <path id="Vector"
                      d="M13 8C13 7.44775 12.5522 7 12 7C11.4478 7 11 7.44775 11 8L11 11L8 11C7.44775 11 7 11.4478 7 12C7 12.5522 7.44775 13 8 13L11 13L11 16C11 16.5522 11.4478 17 12 17C12.5522 17 13 16.5522 13 16L13 13L16 13C16.5522 13 17 12.5522 17 12C17 11.4478 16.5522 11 16 11L13 11L13 8Z"
                      fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
            </g>
        </svg>


    );
}
