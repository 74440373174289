import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import LinkStatus from '@/pages/TeamsPage/components/LinkStatus';
import { CreateUserForm } from '@/components/forms/CreateUserForm';
import { UpdateUserForm } from '@/components/forms/UpdateUserForm';
import { Modal } from '@/components/Modal';
import PageHeader from '@/components/PageHeader';
import Table from '@/components/Table';
import TableColumn from '@/components/Table/components/TableColumn/TableColumn';
import TableRow from '@/components/Table/components/TableRow';
import { ITableDataHeader } from '@/components/Table/Table';
import UserCard from '@/components/UserCard';
import Button from '@/UI/Button';
import { ModalContext } from '@/providers/ModalProvider';
import useFetchUsers from '@hooks/query/users/useFetchUsers';

import s from './TeamsPage.module.scss';

export default function TeamsPage() {
    const { data } = useFetchUsers();
    const { t } = useTranslation();
    const headerData: ITableDataHeader[] = [
        { title: 'Name' },
        { title: 'Email' },
        { title: 'Role' },
        { title: 'Status', position: 'flex-end' },
    ];

    const headerGrid = [
        'minmax(240px, 1fr)',
        'minmax(240px, 360px)',
        'minmax(240px, 360px)',
        'minmax(240px, 360px)',
    ];

    const { modals, open, close } = useContext(ModalContext);

    return (
        <>
            <div className={s.container}>
                <PageHeader title="pages.teams" childrenPosition="start">
                    <Button
                        onClick={() => open({ key: 'CreateUserModal' })}
                        title="buttonActions.createUser"
                    >
                        {t('buttonActions.createUser')}
                    </Button>
                </PageHeader>
                <div className={s.container__content}>
                    <Table dataHeaders={headerData} gridWidthSize={headerGrid}>
                        {data &&
                            data.map((item, rowIndex) => (
                                <Fragment key={rowIndex}>
                                    <TableRow
                                        isClickable={true}
                                        gridWidthSize={headerGrid}
                                        onClick={() =>
                                            open({
                                                key: `UpdateUserModal_${item.id}`,
                                            })
                                        }
                                    >
                                        <TableColumn>
                                            <UserCard
                                                name={item.name}
                                                pictureUrl={item.picture}
                                            />
                                        </TableColumn>
                                        <TableColumn>
                                            <div>{item.email}</div>
                                        </TableColumn>
                                        <TableColumn>
                                            <div>{item.role}</div>
                                        </TableColumn>
                                        <TableColumn position="flex-end">
                                            <LinkStatus user={item} />
                                        </TableColumn>
                                    </TableRow>
                                    <Modal name={`UpdateUserModal_${item.id}`}>
                                        <UpdateUserForm user={item} />
                                    </Modal>
                                </Fragment>
                            ))}
                    </Table>
                </div>
            </div>
            <Modal name={'CreateUserModal'}>
                <CreateUserForm />
            </Modal>
        </>
    );
}
