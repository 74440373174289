import React, { createContext, ReactNode, useContext, useState } from 'react';

interface PopupPosition {
    top: number;
    left: number;
}

interface PopupContextType {
    activePopup: number | null;
    popupPosition: PopupPosition | null;
    isPopupVisible: boolean;
    showPopup: (id: number, position: PopupPosition) => void;
    hidePopup: () => void;
}

const defaultValue: PopupContextType = {
    activePopup: null,
    popupPosition: null,
    isPopupVisible: false, // Инициализировано значением
    showPopup: () => {},
    hidePopup: () => {},
};

const PopupContext = createContext<PopupContextType>(defaultValue);

export const usePopup = () => useContext(PopupContext);

type PopupProviderProps = {
    children: ReactNode;
};

export const PopupProvider = ({ children }: PopupProviderProps) => {
    const [activePopup, setActivePopup] = useState<number | null>(null);
    const [popupPosition, setPopupPosition] = useState<PopupPosition | null>(null);
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

    const showPopup = (id: number, position: PopupPosition) => {
        setActivePopup(id);
        setPopupPosition(position);
        setIsPopupVisible(true);
    };
    const hidePopup = () => {
        setIsPopupVisible(false);

        setTimeout(() => {
            setActivePopup(null);
            setPopupPosition(null);
        }, 300);
    };

    return (
        <PopupContext.Provider value={{ activePopup, popupPosition, isPopupVisible, showPopup, hidePopup }}>
            {children}
        </PopupContext.Provider>
    );
};
