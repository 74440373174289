import Api from "@/api/Api";
import {LoginUsersDto} from "@/api/auth/dto/login.users.dto";

class AuthMethods extends Api {
  async login(dto: LoginUsersDto) {
    const {data} = await this.request<{ access_token: string }, LoginUsersDto>('/auth/login', {
      method: 'POST',
      data: dto
    })

    return data
  }
}

export default new AuthMethods()