import {useMutation} from "@tanstack/react-query";
import {queryClient} from "@/App";
import tasksMethods from "@/api/tasks/TasksMethods";

const useMutationTaskRemove = () => {
    return useMutation<void, Error, { id: number }>({
        mutationFn: async ({id}) => {
            return await tasksMethods.removeById(id);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["tasks"]});
            queryClient.invalidateQueries({queryKey: ["statuses-task"]});
        },
    })
};

export default useMutationTaskRemove;
