import {useQuery} from "@tanstack/react-query";
import ReportsMethods from "@/api/reports/ReportsMethods";
import {ReportsDto} from "@/api/reports/dto/reports.dto";
import {ReportsOptionsDto} from "@/api/reports/dto/reports-options.dto";

const useFetchReports = (query: ReportsOptionsDto) => {
    return useQuery<ReportsDto[], null>({
        queryKey: ["reports"],
        queryFn: async () => {
            return ReportsMethods.get(query);
        },
    });
};

export default useFetchReports;
