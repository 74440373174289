import React from "react";
import './i18n/config'

import {Provider} from "react-redux";

import store from "./store";
import Router from "./Router";

import LangInitializer from "@/components/helpers/LangInitializer";
import Close from "@/icons/Close";
import {ToastContainer} from "react-toastify";
import AuthProvider from "@/providers/AuthProvider";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import TimerProvider from "@/providers/TimerProvider";
import ModalProvider from "./providers/ModalProvider";
import ConfirmProvider from "@/providers/ConfirmProvider";
import {TabsProvider} from "@/providers/TabsProvider";
import {PopupProvider} from "@/providers/PopupProvider";

export const queryClient = new QueryClient()

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <ConfirmProvider>
                    <LangInitializer/>
                    <AuthProvider>
                        <TimerProvider>
                            <TabsProvider>
                                <ModalProvider>
                                    <PopupProvider>
                                        <Router/>
                                    </PopupProvider>
                                </ModalProvider>
                            </TabsProvider>
                        </TimerProvider>
                    </AuthProvider>
                    <ToastContainer
                        closeButton={(props) => (<div className="Toastify__close-button" {...props}><Close/></div>)}/>
                    {/*<ReactQueryDevtools/>*/}
                </ConfirmProvider>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
