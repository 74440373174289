import {createContext, ReactNode, useEffect, useState} from "react";
import {setProfile} from "@store/reducers/profileReducer";
import UseFetchProfile from "@hooks/query/profile/useFetchProfile";
import {useAppDispatch, useAppSelector} from "@hooks/store";
import {FullScreenLoader} from "@/UI/FullScreenLoader/FullScreenLoader";

export type AuthContextType = {
    isAuthenticated: boolean;
    setAuth: (auth: boolean) => void;
};

export const AuthContext = createContext<AuthContextType>({
    isAuthenticated: false,
    setAuth: () => {
    },
});

type AuthProviderProps = {
    children: ReactNode;
}

const Provider = ({children}: AuthProviderProps) => {
    const profile = useAppSelector((state) => state.profile.profile)
    const token = useAppSelector((state) => state.auth.token)

    const [isAuthenticated, setAuth] = useState<boolean>(!!token);
    const [isFinish, setIsFinish] = useState<boolean>(false);
    const {data, isLoading} = UseFetchProfile()
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isAuthenticated && !profile && data) {
            dispatch(setProfile(data))
            setIsFinish(true)
        }
    }, [isAuthenticated, isLoading, data, profile])

    return (
        <AuthContext.Provider value={{isAuthenticated, setAuth}}>
            {token && <FullScreenLoader delayIn={300} disabled={isFinish}/>}
            {children}
        </AuthContext.Provider>
    )
}

export default Provider;