import {createContext, ReactNode, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "@hooks/store";
import {TimerDto} from "@/api/timer/dto/timer.dto";
import UseFetchCurrentTimer from "@hooks/query/timer/useFetchCurrentTimer";
import {setRunning, setTimer} from "@store/reducers/timerReducer";

export type TimerContextType = {
    timer: TimerDto | undefined;
    setTimer: (timer: TimerDto) => void;
};

export const TimerContext = createContext<TimerContextType>({
    timer: undefined,
    setTimer: () => {
    },
});

type TimerProviderProps = {
    children: ReactNode;
}

const Provider = ({children}: TimerProviderProps) => {
    const {timer, isRunning} = useAppSelector((state) => state.timer)
    const {data, isLoading} = UseFetchCurrentTimer()
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!timer && data) {
            dispatch(setTimer(data))
            dispatch(setRunning(true))
        }
    }, [isLoading, data])

    return (
        <TimerContext.Provider value={{timer, setTimer}}>
            {children}
        </TimerContext.Provider>
    )
}

export default Provider;