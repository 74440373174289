import {yupResolver} from '@hookform/resolvers/yup';
import {DateTime} from 'luxon';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import React, {useContext, useEffect, useMemo, useState} from 'react';

import {SchemaTaskCreateOrUpdate} from '@/utils/config/yupShemes';
import {PriorityEnum} from '@/api/tasks/interfaces/priority.enum';
import {TaskDto} from '@/api/tasks/dto/task.dto';
import {CreateUpdateTaskDto} from '@/api/tasks/dto/create-update.task.dto';

import useFetchStatusesTasks from '@/hooks/query/statuses/useFetchStatusesTasks';
import useFetchUsers from '@/hooks/query/users/useFetchUsers';
import useMutationTaskUpdate from '@/hooks/mutations/tasks/useMutationTaskUpdate';
import useMutationTaskRemove from '@/hooks/mutations/tasks/useMutationTaskRemove';

import {ModalContext} from '@/providers/ModalProvider';

import Badge from '@/UI/Badge';
import Button from '@/UI/Button';
import Input from '@/UI/Input';
import {Select, SelectActions, SelectCalendar, SelectUser} from '@/UI/Select';
import {TextArea} from '@/UI/TextArea';
import Toasts from '@/utils/Toasts';
import {ModalForm} from '@/components/Modal/components/ModalForm';
import {ModalWrapper} from '@/components/Modal/components/ModalWrapper';

import Trash from '@/icons/Trash';
import useConfirm from "@hooks/useConfirm";
import ConfirmTitle from "@/components/Confirm/components/ConfirmTitle/ConfirmTitle";
import ConfirmList from "@/components/Confirm/components/ConfirmList/ConfirmList";
import {StatusesShortDto} from "@/api/statuses/dto/statuses-short.dto";
import Dots from "@/icons/Dots";
import Archive from "@/icons/Archive";
import {SelectActionOption} from "@/UI/Select/SelectActions";
import {UpdateTaskDto} from "@/api/tasks/dto/update.task.dto";
import TagCreator from "@/UI/TagCreator";

type UpdateTaskFormProps = {
    task: TaskDto,
    status: StatusesShortDto
}

export const UpdateTaskForm = ({task, status}: UpdateTaskFormProps) => {
    const {closeAll} = useContext(ModalContext)
    const {confirm} = useConfirm()

    const defaultValues = useMemo(() => {
        return {
            deadline: task.deadline ?? undefined,
            description: task.description,
            index: task.index,
            priority: task.priority,
            responsible: task.responsible.map((item) => item.id),
            start_date: task.start_date ?? undefined,
            statuses: status?.id,
            tags: task.tags,
            title: task.title
        }
    }, [task, status])



    const {
        register,
        formState: {errors, isValid, isDirty},
        handleSubmit,
        getValues,
        setValue,
        reset,
        watch
    } = useForm<CreateUpdateTaskDto>({
        resolver: yupResolver(SchemaTaskCreateOrUpdate),
        mode: 'all',
        defaultValues: defaultValues,
    });

    const defaultChangeValueOptions = {shouldTouch: true, shouldDirty: true, shouldValidate: true}

    const {data: statuses} = useFetchStatusesTasks()
    const {data: users} = useFetchUsers()

    const updateTask = useMutationTaskUpdate()
    const removeTask = useMutationTaskRemove()

    const {t} = useTranslation()

    const remove = async () => {
        const result = await confirm(<>
            <ConfirmTitle>Are you sure you want to delete it?</ConfirmTitle>
            <ConfirmList items={[
                'This action cannot be undone',
                'Things related to tasks are also deleted. For example, the time'
            ]} className={'color-error'}/>
        </>)
        if (result) {
            removeTask.mutateAsync({
                id: task.id
            })
                .then(() => {
                    closeAll()
                    Toasts.success(t('alerts.tasks.remove.success'))
                })
                .catch(() => {
                    Toasts.error(t('alerts.tasks.remove.error'))
                })
        }
    }

    const submit = handleSubmit((data: CreateUpdateTaskDto) => {
        updateTask.mutateAsync({
            id: task.id,
            body: {
                ...data,
                start_date: data.start_date === undefined ? null : data.start_date,
                deadline: data.deadline === undefined ? null : data.deadline,
                description: data.description ?? null,
                tags: data.tags
            }
        })
            .then((res) => {
                Toasts.success(t('alerts.tasks.update.success'))
            })
            .catch(() => {
                Toasts.error(t('alerts.tasks.update.error'))
            })
    })

    const handleInArchive = async () => {
        await updateTask.mutateAsync({
            id: task.id,
            body: {
                is_archive: true,
            }
        })
    }

    const selectActions: SelectActionOption[] = useMemo(() => [
        {
            value: 'Delete', optionComponent: {
                icon: <Trash/>,
                text: "selects.delete",
                action: () => remove()
            }
        },
        {
            value: 'In Archive', optionComponent: {
                icon: <Archive/>,
                text: "selects.inArchive",
                action: () => handleInArchive()
            }
        },
    ], []);


    useEffect(() => {
        reset(defaultValues)
    }, [task])




    return (
        <ModalForm onSubmit={submit}>
            <ModalWrapper type='header'>
                <Input
                    error={errors['title']?.message ? t(errors['title']?.message) : undefined}
                    {...register('title')}
                    placeholder={t('forms.tasks.placeholder.title')}
                />
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <Button type='submit' disabled={!isDirty || !isValid}>{t('buttonActions.update')}</Button>
                    <SelectActions optionsList={selectActions}>
                        <Dots/>
                    </SelectActions>
                </div>
            </ModalWrapper>
            <ModalWrapper type='block'>
                <ModalWrapper type="block_left">
                    <Select
                        value={
                            getValues('statuses') && statuses ?
                                {
                                    value: statuses[statuses.findIndex((el) => el.id === getValues('statuses'))].id,
                                    optionComponent: statuses[statuses.findIndex((el) => el.id === getValues('statuses'))].title,
                                    color: statuses[statuses.findIndex((el) => el.id === getValues('statuses'))].color
                                }
                                : undefined
                        }
                        setValue={(value) => {
                            if (value && value.value) {
                                setValue('statuses', Number(value.value), defaultChangeValueOptions)
                            }
                        }}
                        optionsList={statuses?.map((item) => {
                            return {
                                value: item.id,
                                optionComponent: item.title,
                                color: item.color
                            }
                        })}
                    />
                    <TextArea
                        label={t('forms.tasks.label.description')}
                        error={errors['description']?.message ? t(errors['description']?.message) : undefined}
                        {...register('description')}
                        placeholder={t('forms.tasks.placeholder.description')}
                    />
                </ModalWrapper>
                <ModalWrapper type="block_right">
                    <div>
                        <p>{t('forms.tasks.label.start_date')}</p>
                        <SelectCalendar
                            value={
                                getValues('start_date') ?
                                    DateTime.fromISO((getValues('start_date') as string))
                                    : null
                            }
                            setValue={(date) => {
                                setValue('start_date', date?.toISO(), defaultChangeValueOptions)
                            }}
                        />
                    </div>
                    <div>
                        <p>{t('forms.tasks.label.deadline')}</p>
                        <SelectCalendar
                            value={
                                getValues('deadline') ?
                                    DateTime.fromISO((getValues('deadline') as string))
                                    : null
                            }
                            setValue={(date) => {
                                setValue('deadline', date?.toISO(), defaultChangeValueOptions)
                            }}
                            canSelectPrevious={!Boolean(getValues('start_date'))}
                            previousStartDay={getValues('start_date') ? DateTime.fromISO((getValues('start_date') as string)) : undefined}
                        />
                    </div>
                    <div>
                        <p>{t('forms.tasks.label.priority')}</p>
                        <Select
                            value={
                                getValues('priority') ? {
                                        value: String(getValues('priority')),
                                        optionComponent: <Badge
                                            priority={PriorityEnum[(String(getValues('priority')) as keyof typeof PriorityEnum)]}>
                                            {t(`task.priority.${String(getValues('priority'))}`)}
                                        </Badge>
                                    }
                                    : undefined
                            }
                            setValue={(value) => {
                                if (value && value.value) {
                                    setValue('priority', PriorityEnum[(String(value.value) as keyof typeof PriorityEnum)], defaultChangeValueOptions)
                                }
                            }}
                            optionsList={Object.values(PriorityEnum).map((item) => {
                                return {
                                    value: item,
                                    optionComponent: <Badge
                                        priority={PriorityEnum[(item as keyof typeof PriorityEnum)]}>{t(`task.priority.${item}`)}</Badge>
                                }
                            })}
                        />
                    </div>
                    <div>
                        <p>{t('forms.tasks.label.responsible')}</p>
                        <input type="hidden" {...register('responsible')} />
                        <SelectUser
                            value={
                                getValues('responsible') ?
                                    users?.filter((el) => (getValues('responsible') as number[]).some((item) => item === el.id))
                                    : undefined
                            }
                            optionsList={users?.map((item) => {
                                return item
                            })}
                            setValue={(value) => {
                                if (value) {
                                    setValue('responsible', value.map((item) => item.id), defaultChangeValueOptions)
                                }
                            }}
                        />
                    </div>
                    <div>
                        <p >{t('inputs.placeholder.tags')}</p>
                        <div/>
                        <TagCreator
                            onChange={(newTags) => {
                                setValue('tags', newTags, { shouldValidate: true, shouldDirty: true });
                            }}
                            value={getValues('tags') || []}
                        />

                    </div>
                </ModalWrapper>
            </ModalWrapper>
        </ModalForm>
    )
};
