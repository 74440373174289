import React, {useMemo} from 'react';

import {useTranslation} from "react-i18next";

import {Link} from "react-router-dom";

import NavLink from "@/layouts/MainLayout/LayoutAside/components/NavLink/NavLink";

import Logotype from "@/components/Logotype/Logotype";
import Home from "@/icons/Home";
import Users from "@/icons/Users";
import Chart from "@/icons/Chart";
import Settings from "@/icons/Settings";

import s from './LayoutAside.module.scss'
import {RightsEnum} from "@/api/users/interfaces/rights.enum";
import {useAppSelector} from "@hooks/store";

export default function LayoutAside() {
    const profile = useAppSelector((state) => state.profile.profile)
    const {t} = useTranslation();

    const columnsData = useMemo(() => [
        {
            links: [
                {text: `${t('asideNavigation.dashboard')}`, id:1, icon: <Home/>, url: '/', selector: true},
                // {text: `${t('asideNavigation.projects')}`, icon: <Clipboard/>, url: '/projects'},
                // {text: `${t('asideNavigation.database')}`, icon: <Database/>, url: '/database'},
                {text: `${t('asideNavigation.teams')}`, id:2, icon: <Users/>, url: '/teams', rights: RightsEnum.ADMIN},
                {text: `${t('asideNavigation.reports')}`, id:3, icon: <Chart/>, url: '/reports'}
            ]
        },
        {
            links: [
                {text: `${t('asideNavigation.settings')}`,id:4, icon: <Settings/>, url: '/settings'},
                // {text: `${t('asideNavigation.privacy')}`, icon: <Incognito/>, url: '/privacy'}
            ]
        }
    ], [t, profile]);


    const content = useMemo(() => {
        if (profile)
            return (
                <div className={s.content}>
                    <Link to="/"><Logotype/></Link>
                    <nav className={s.navigation}>
                        {columnsData.map((column, index) => (
                            <div key={index} className={s.navColumn}>
                                {column.links.map((link, linkIndex) => (
                                    (link.rights === RightsEnum.ADMIN) ? (profile && profile.rights === RightsEnum.ADMIN) ?
                                            <NavLink text={link.text} icon={link.icon} url={link.url} id={link.id}
                                                     key={linkIndex} selector={link.selector}/> : null :
                                        <NavLink text={link.text} icon={link.icon} url={link.url} key={linkIndex} selector={link.selector} id={link.id}/>
                                ))}
                            </div>
                        ))}
                    </nav>
                </div>
            )
    }, [t, profile])

    return (
        <aside className={s.aside}>
            {content}
        </aside>
    )
}
