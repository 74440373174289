import {useTranslation} from "react-i18next";

import React, {ReactNode, useCallback} from "react";
import Logotype from "@/components/Logotype/Logotype";
import {setLanguage} from "@store/reducers/settingsReducer";
import {useAppDispatch, useAppSelector} from "@hooks/store";
import s from './WrapperForm.module.scss'

type LoginFormProps = {
    title: string,
    children: ReactNode
}
export default function WrapperForm({title, children}: LoginFormProps) {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const language = useAppSelector((state) => state.settings.language)

    const handleLanguageChange = useCallback(() => {
        const newLanguage = language === 'ru' ? 'en' : 'ru'
        dispatch(setLanguage(newLanguage))
    }, [dispatch, language])

    return (
        <div className={s.container}>
            <p className={s.title}>{title}</p>
            <div className={s.dialog}>
                <div className={s.form}>
                    <Logotype/>
                    {children}
                </div>
            </div>
            <div className={s.footer}>
                <button className={s.lang} type="button"
                        onClick={handleLanguageChange}>{t('buttonActions.switchLanguage')}</button>
            </div>
        </div>
    )
}