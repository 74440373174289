import {QueryPagination} from "@/api/Api";
import queryString from 'query-string';

export default function (query?: QueryPagination, rawQuery?: string): string | undefined {
    if (query && rawQuery) {
        return '?' + queryString.stringify(query) + `&${rawQuery}`;
    }
    if (query) {
        return '?' + queryString.stringify(query)
    }
    if(rawQuery) {
        return `?${rawQuery}`
    }
    return ''
}
