import React, {useEffect, useState} from 'react';
import {DateTime} from "luxon";
import {useTranslation} from "react-i18next";

interface DateProps extends React.HTMLAttributes<HTMLDivElement> {
    time: string | null | undefined;
}

export default function Date ({time, ...props}: DateProps) {
    const [dateTime, setDateTime] = useState<string | undefined>(undefined);

    const {t} = useTranslation()

    useEffect(() => {
        if (time) {
            const dateTime = DateTime.fromISO(time);
            if (dateTime.isValid) {
                const datePart = dateTime.toFormat('LLLL dd, yyyy');
                const timePart = dateTime.toFormat('HH:mm');
                setDateTime(`${datePart} ${t('forms.tasks.label.at')} ${timePart}`);
            }
        }
    }, [time]);

    return (
        <div {...props}>
            {dateTime}
        </div>
    )
}