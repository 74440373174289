import React, {ReactNode, useCallback, useContext, useRef, useState} from 'react';
import classNames from "classnames";
import {Link, useLocation, useNavigate} from "react-router-dom";
import s from './NavLink.module.scss';
import Ripple from "@/components/Ripple";
import ArrowDown from "@/icons/ArrowDown";
import {usePopup} from "@/providers/PopupProvider";
import PopupSelector from "@/components/PopupSelector";
import PopupSelectorItem from "@/components/PopupSelector/components/PopupSelectorItem";
import List from "@/icons/List";
import Add from "@/icons/Add";
import Kanban from "@/icons/Kanban";
import Plan from "@/icons/Plan";
import Archive from "@/icons/Archive";
import ScheduleTasks from "@/components/ScheduleTasks";
import {ModalContext} from "@/providers/ModalProvider";

interface NavLinkProps {
    text: string;
    icon: ReactNode;
    url: string;
    id: number;
    selector?: boolean;
}

export default function NavLink({text, icon, url, selector, id}: NavLinkProps) {
    const location = useLocation();
    const navigation = useNavigate();
    const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
    const {activePopup, popupPosition, showPopup, hidePopup} = usePopup();
    const buttonRef = useRef<HTMLButtonElement>(null);

    const {open} = useContext(ModalContext)

    const handleOnClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (activePopup === id) {
            hidePopup();
            setIsButtonActive(false);
        } else {
            const rect = buttonRef.current?.getBoundingClientRect();
            if (rect) {
                const adjustedTop = rect.top - 18;
                const adjustedLeft = rect.right + 52;
                showPopup(id, {top: adjustedTop, left: adjustedLeft});
                setIsButtonActive(true);
            }
        }
    }, [activePopup, id, showPopup, hidePopup]);

    const popupStyle: React.CSSProperties = {
        position: 'absolute',
        top: `${popupPosition?.top}px` || '0px',
        left: `${popupPosition?.left}px` || '0px',
    };

    const openModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        navigation('/')
        open({key: `CreateTaskModal`})
    }


    return (
        <div>
            <Link to={url}>
                <div className={classNames({
                    [s.navLink]: true,
                    [s.active]: location.pathname === url,
                    [s.disabled]: location.pathname !== url
                })}>
                    <div className={s.navLink__leftPart}>
                        {icon}
                    </div>
                    <div className={s.navLink__rightPart}>
                        {text}
                        {selector && (
                            <button ref={buttonRef} onClick={handleOnClick} className={classNames({
                                [s.navLink__rightPart__button]: true,
                                [s.navLink__rightPart__button__active]: isButtonActive
                            })}>
                                <ArrowDown style={{width: '12px', height: '12px'}}/>
                                <Ripple duration={850} color="#ffffff"/>
                            </button>
                        )}
                    </div>
                    <Ripple duration={850} color="#ffffff"/>
                </div>
            </Link>
            {activePopup === id && <PopupSelector style={popupStyle} onOutsideClick={() => {
                hidePopup();
                setIsButtonActive(false);
            }}>
                <PopupSelectorItem icon={<Add/>} text={"popupSelector.createTask"} onClick={openModal}/>
                <PopupSelectorItem icon={<Kanban/>} text={"popupSelector.kanban.title"} tooltip={"popupSelector.kanban.subTitle"} onClick={() => {
                    navigation('/')
                }}/>
                {/*<PopupSelectorItem icon={<List/>} text={"List"} tooltip={"Display as a list"}/>*/}
                {/*<PopupSelectorItem icon={<Plan/>} text={"Planning"} tooltip={"Plan your tasks"}/>*/}
                <PopupSelectorItem>
                    <ScheduleTasks/>
                </PopupSelectorItem>
                <PopupSelectorItem icon={<Archive/>} text={"popupSelector.archive.title"} onClick={() => {navigation('/tasks/archive')}} tooltip={"popupSelector.archive.subTitle"}/>
            </PopupSelector>}
        </div>
    );
}
