import React, {useContext} from "react";

import {useTranslation} from "react-i18next";
import {useAppDispatch} from "@hooks/store";
import {useLocation, useNavigate} from "react-router-dom";

import AuthMethods from "@/api/auth/AuthMethods";
import Input from "@/UI/Input";
import {setToken} from "@store/reducers/authReducer";
import {useForm} from "react-hook-form";
import {ILoginForm} from "@/utils/Forms";
import {yupResolver} from "@hookform/resolvers/yup";
import {SchemaLogin} from "@/utils/config/yupShemes";
import {AuthContext} from "@/providers/AuthProvider";
import Button from "@/UI/Button";
import Toasts from "@/utils/Toasts";

export default function LoginForm() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigation = useNavigate()
    const location = useLocation();
    const {setAuth} = useContext(AuthContext);

    const from = location.state?.from?.pathname || '/';

    const {
        register,
        formState: {errors},
        handleSubmit
    } = useForm<ILoginForm>({
        resolver: yupResolver(SchemaLogin),
        mode: 'onBlur',
    });

    const submit = async (data: ILoginForm) => {
        try {
            const {access_token} = await AuthMethods.login({email: data.email, password: data.password})
            dispatch(setToken(access_token))

            Toasts.success(t('alerts.authorization.success'))
            window.location.reload();
            setAuth(true)
            navigation(from, {replace: true})
        } catch (e) {
            Toasts.error(t('alerts.authorization.error'))
        }
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Input type='email' label={t('inputs.label.email')} placeholder={t('inputs.placeholder.email')}
                   error={errors['email']?.message ? t(errors['email']?.message) : undefined}
                   {...register('email', {required: true})}/>
            <Input type='password' label={t('inputs.label.password')}
                   placeholder={t('inputs.placeholder.password')}
                   error={errors['password']?.message ? t(errors['password']?.message) : undefined}
                   {...register('password', {required: true})}/>
            <div>
                <Button type="submit">{t('buttonActions.login')}</Button>
            </div>
        </form>
    )
}