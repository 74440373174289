import {useMutation} from "@tanstack/react-query";
import statusesMethods from "@/api/statuses/StatusesMethods";
import {queryClient} from "@/App";

const useMutationStatusesRemove = () => {
    return useMutation<void, Error, { id: number }>({
        mutationFn: async ({id}) => {
            return await statusesMethods.removeById(id);
        },
        onSuccess: (data, {id}) => {
            queryClient.invalidateQueries({queryKey: ["statuses-task"]});
            queryClient.invalidateQueries({queryKey: ["statuses"]});
        },
    })
};

export default useMutationStatusesRemove;
