import React, {useCallback, useEffect, useMemo, useState} from 'react';
import s from './SettingsStatuses.module.scss';
import {StatusesShortDto} from "@/api/statuses/dto/statuses-short.dto";
import SettingsStatusItem from "@/pages/SettingsPage/components/SettingsStatusItem/SettingsStatusItem";
import Button from "@/UI/Button";
import {DateTime} from "luxon";
import {DragDropContext, Droppable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import Toasts from "@/utils/Toasts";
import {useTranslation} from "react-i18next";
import useMutationStatusesUpdateIndexes from "@hooks/mutations/statuses/useMutationStatusesUpdateIndexes";
import useMutationStatusesRemove from "@hooks/mutations/statuses/useMutationStatusesRemove";
import {reorder} from "@/utils/reorder";

export interface SettingsStatusesShortDto extends StatusesShortDto {
    isNew?: boolean
}

type SettingsStatusItemProps = {
    statuses: SettingsStatusesShortDto[],
}
export default function SettingsStatuses({statuses}: SettingsStatusItemProps) {
    const {t} = useTranslation();

    const [localStatuses, setLocalStatuses] = useState<SettingsStatusesShortDto[]>(statuses);
    const updateStatusIndexes = useMutationStatusesUpdateIndexes()
    const removeStatus = useMutationStatusesRemove()

    const isCreatedStatus = useMemo(() => {
        return localStatuses.findIndex(status => status.isNew) !== -1
    }, [localStatuses, statuses]);


    useEffect(() => {
        setLocalStatuses((statuses))
    }, [statuses])

    const addStatus = useCallback(() => {
        setLocalStatuses((prevState) => ([...prevState, {
            id: Math.round(DateTime.now().toMillis()),
            title: "",
            color: "",
            index: (localStatuses.length + 1) * -1,
            isNew: true
        }]))
    }, [localStatuses, statuses])

    function handleDragEnd(result: DropResult, provided: ResponderProvided) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            localStatuses,
            result.source.index,
            result.destination.index
        )

        setLocalStatuses(items.map((status, index) => {
            status.index = index * -1
            return status
        }))

        const draggableId = parseInt(result.draggableId)
        if (draggableId) {
            const item = items.find((item) => item.id === draggableId)

            if (item && !item.isNew) {
                updateStatusIndexes.mutateAsync({
                    body: items.map((_item) => ({
                        id: _item.id,
                        index: _item.index
                    }))
                }).catch(() => Toasts.error(t('alerts.statuses.update.error')))
            }
        }
    }

    const remove = useCallback((id: number) => {
        const find = localStatuses.find((item) => item.id === id)

        if (find) {
            if (!find.isNew) {
                removeStatus.mutateAsync({id: id}).then(() => {
                    Toasts.success(t('alerts.statuses.remove.success'))
                    setLocalStatuses((prevState) => prevState.filter((item) => item.id !== id))
                }).catch(() => {
                    Toasts.error(t('alerts.statuses.remove.error'))
                })
            } else {
                setLocalStatuses((prevState) => prevState.filter((item) => item.id !== id))
            }
        }

    }, [localStatuses, statuses])

    return (
        <div className={s.statuses}>
            <div className={s.statuses__main}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId={"droppable"}>
                        {(provided, snapshot) => (
                            <div className={s.kanbanRow__content__items} ref={provided.innerRef}
                                 {...provided.droppableProps}>
                                {localStatuses.map((status, index) => (
                                    <SettingsStatusItem key={status.id} status={status} index={index}
                                                        onRemove={remove}/>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className={s.statuses__footer}>
                <Button type="button" onClick={addStatus} disabled={isCreatedStatus}>{t('buttonActions.create')}</Button>
            </div>
        </div>
    );
}
