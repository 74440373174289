import {useMutation} from "@tanstack/react-query";
import {TimerDto} from "@/api/timer/dto/timer.dto";
import TimerMethods from "@/api/timer/TimerMethods";

const useMutationTimerStart = () => {
    return useMutation<TimerDto, Error, { taskId: number }>({
        mutationFn: async ({taskId}) => {
            return await TimerMethods.start(taskId);
        },
    })
};

export default useMutationTimerStart;
