import React, {ReactNode} from 'react';
import TableRow from './components/TableRow';
import s from "./Table.module.scss";
import TableColumn from "@/components/Table/components/TableColumn/TableColumn";

export interface ITableDataHeader {
    title: string;
    position?: 'flex-start' | 'flex-end'
}

type TableProps = {
    dataHeaders: ITableDataHeader[],
    gridWidthSize: Array<string | number>,
    children: ReactNode;
};

export default function Table({dataHeaders, gridWidthSize, children}: TableProps) {

    return (
        <div className={s.container}>
            <div className={s.table}>
                <TableRow gridWidthSize={gridWidthSize} isClickable={false}>
                    {dataHeaders.map((header, index) => (
                        <TableColumn position={header.position || 'flex-start'}>
                            <div key={index} className={s.table__header}>{header.title}</div>
                        </TableColumn>
                    ))}
                </TableRow>
                {children}
            </div>
        </div>
    );
}
