import React from 'react';
import s from './CustomTab.module.scss'
import {ReactTabsFunctionComponent, Tab, TabProps} from "react-tabs";


export const CustomTab: ReactTabsFunctionComponent<TabProps> = ({
    children,
    ...otherProps
}) => (
    <Tab className={s.tab} selectedClassName={s.tab__active} {...otherProps}>
        {children}
    </Tab>
);

CustomTab.tabsRole = 'Tab';