import {useMutation} from "@tanstack/react-query";
import {queryClient} from "@/App";
import {TaskDto} from "@/api/tasks/dto/task.dto";
import tasksMethods from "@/api/tasks/TasksMethods";
import {UpdateTaskDto} from "@/api/tasks/dto/update.task.dto";
import {UpdateIndexTaskDto} from "@/api/tasks/dto/update-index.task.dto";

const useMutationTaskUpdateIndexes = () => {
    return useMutation<void, Error, { body: UpdateIndexTaskDto[] }>({
        mutationFn: async ({body}) => {
            return await tasksMethods.updateIndexes(body);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["tasks"]});
        },
    })
};

export default useMutationTaskUpdateIndexes;
