import {useQuery} from "@tanstack/react-query";
import {TaskDto} from "@/api/tasks/dto/task.dto";
import TasksMethods from "@/api/tasks/TasksMethods";
import {Pagination, QueryPagination} from "@/api/Api";

const useFetchTasks = (query?: QueryPagination, rawQuery?: string) => {
    return useQuery<Pagination<TaskDto>, null>({
        queryKey: ["tasks", "tasks-" + JSON.stringify(query)],
        queryFn: async () => {
            return TasksMethods.get(query, rawQuery);
        },
    });
};

export default useFetchTasks;
