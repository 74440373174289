import {useMutation} from "@tanstack/react-query";
import {TimerDto} from "@/api/timer/dto/timer.dto";
import TimerMethods from "@/api/timer/TimerMethods";

const useMutationTimerStop = () => {
    return useMutation<TimerDto, Error>({
        mutationFn: async () => {
            return await TimerMethods.stop();
        },
    })
};

export default useMutationTimerStop;
