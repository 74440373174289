import React, {Dispatch, MouseEventHandler, SetStateAction, useCallback} from 'react';

import ArrowDown from "@/icons/ArrowDown";
import s from './UserRowInfo.module.scss'
import classNames from "classnames";
import {UserDto} from "@/api/users/dto/user.dto";
import Avatar from "@/UI/Avatar";
import Ripple from "@/components/Ripple";

type UserRowInfoProps = {
    isUserRowOpen: boolean
    overallTime: string
    user: UserDto
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
export default function UserRowInfo({onClick, isUserRowOpen, user, overallTime}: UserRowInfoProps) {
    return (
        <button type='button' onClick={onClick} className={s.userRowInfo}>
            <div className={s.userRowInfo__wrapper}>
                <div className={s.userRowInfo__wrapper__leftPart}>
                    <div className={s.userRowInfo__wrapper__leftPart__avatar}>
                        <Avatar name={user.name} picture={user.picture}/>
                    </div>
                    <div className={s.userRowInfo__wrapper__leftPart__nickname}>
                        {user.name}
                    </div>
                </div>
                <div className={s.userRowInfo__wrapper__rightPart}>
                    <div className={s.userRowInfo__wrapper__rightPart__overallTime}>
                        {overallTime}
                    </div>
                    <div className={classNames({
                        [s.userRowInfo__wrapper__rightPart__arrow]: true,
                        [s.userRowInfo__wrapper__rightPart__arrow_active]: isUserRowOpen
                    })}>
                        <ArrowDown/>
                    </div>
                </div>
            </div>
            <Ripple duration={850} color="#ffffff"/>
        </button>
    )
}
