import React, { useCallback, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { SettingsStatusesShortDto } from '@/pages/SettingsPage/components/SettingsStatuses/SettingsStatuses';
import Button from '@/UI/Button';
import Input from '@/UI/Input';
import useMutationStatusesCreate from '@hooks/mutations/statuses/useMutationStatusesCreate';
import useMutationStatusesUpdate from '@hooks/mutations/statuses/useMutationStatusesUpdate';
import { SchemaStatuses } from '@/utils/config/yupShemes';
import { IStatusesForm } from '@/utils/Forms';
import Toasts from '@/utils/Toasts';
import Drag from '@/icons/Drag';
import Trash from '@/icons/Trash';

import s from './SettingsStatusItem.module.scss';

type SettingsStatusItemProps = {
    status: SettingsStatusesShortDto;
    index: number;
    onRemove: (id: number) => void;
};
export default function SettingsStatusItem({
    status,
    index,
    onRemove,
}: SettingsStatusItemProps) {
    const { t } = useTranslation();

    const createStatus = useMutationStatusesCreate();
    const updateStatus = useMutationStatusesUpdate();

    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        reset,
    } = useForm<IStatusesForm>({
        resolver: yupResolver(SchemaStatuses),
        mode: 'all',
        defaultValues: {
            title: status.title,
            color: status.color,
        },
    });

    useEffect(() => {
        reset({
            title: status.title,
            color: status.color,
        });
    }, [status]);

    const submit = useCallback(
        async (data: IStatusesForm) => {
            if (status.isNew) {
                createStatus
                    .mutateAsync({
                        body: {
                            ...data,
                            index: status.index || 0,
                        },
                    })
                    .then(() => {
                        Toasts.success(t('alerts.statuses.create.success'));
                    })
                    .catch(() => {
                        Toasts.error(t('alerts.statuses.create.error'));
                    });
            } else {
                updateStatus
                    .mutateAsync({ id: status.id, body: data })
                    .then(() => {
                        Toasts.success(t('alerts.statuses.update.success'));
                    })
                    .catch(() => {
                        Toasts.error(t('alerts.statuses.update.error'));
                    });
            }
        },
        [status],
    );

    return (
        <Draggable draggableId={`${status.id}`} key={status.id} index={index}>
            {(provided) => (
                <form
                    onSubmit={handleSubmit(submit)}
                    className={s.status}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Drag />
                    <div
                        className={s.status__inputs}
                        style={{ color: getValues('color') }}
                    >
                        <Input
                            type="text"
                            error={
                                errors['title']?.message
                                    ? t(errors['title']?.message)
                                    : undefined
                            }
                            {...register('title', { required: true })}
                        />

                        <Input
                            type="text"
                            error={
                                errors['color']?.message
                                    ? t(errors['color']?.message)
                                    : undefined
                            }
                            {...register('color', { required: true })}
                        />
                    </div>
                    <Button
                        type="submit"
                        className={s.status__save}
                        disabled={!isDirty || !isValid}
                    >
                        {t('buttonActions.save')}
                    </Button>
                    <button type="button" onClick={() => onRemove(status.id)}>
                        <Trash />
                    </button>
                </form>
            )}
        </Draggable>
    );
}
