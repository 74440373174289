import Api, {Pagination, QueryPagination} from "@/api/Api";
import {CreateTaskDto} from "@/api/tasks/dto/create.task.dto";
import {TaskDto} from "@/api/tasks/dto/task.dto";
import {UpdateTaskDto} from "@/api/tasks/dto/update.task.dto";
import {UpdateIndexTaskDto} from "@/api/tasks/dto/update-index.task.dto";
import {CreateUpdateTaskDto} from "./dto/create-update.task.dto";
import getQuery from "@/utils/getQuery";

class TasksMethods extends Api {
    async create(body: CreateTaskDto) {
        const {data} = await this.request<TaskDto, CreateTaskDto>('/tasks', {
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async get(query?: QueryPagination, rawQuery?: string) {
        const queryString = getQuery(query, rawQuery)

        const {data} = await this.request<Pagination<TaskDto>, null>('/tasks' + queryString, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })
        return data
    }


    async getById(id: number) {
        const {data} = await this.request<TaskDto, null>(`/tasks/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async updateById(id: number, body: CreateUpdateTaskDto | UpdateTaskDto) {
        const {data} = await this.request<TaskDto, UpdateTaskDto | CreateUpdateTaskDto>(`/tasks/${id}`, {
            method: 'PUT',
            data: body,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`
            }
        })

        console.log("data", data)

        return data
    }

    async updateIndexes(body: UpdateIndexTaskDto[]) {
        const {data} = await this.request<void, UpdateIndexTaskDto[]>(`/tasks/update/indexes`, {
            method: 'PUT',
            data: body,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async removeById(id: number) {
        const {data} = await this.request<void, null>(`/tasks/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }
}

export default new TasksMethods()
