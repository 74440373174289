import React, {createContext, useContext, useState, ReactNode, Dispatch, SetStateAction, useRef} from 'react';

type TabsContextType = {
    activeTab: number;
    setActiveTab: Dispatch<SetStateAction<number>>;
    tabsRef: React.MutableRefObject<HTMLElement[]>;
};

type TabsProviderProps = {
    children: ReactNode;
};


export const TabsContext = createContext<TabsContextType | undefined>(undefined);

export const useTabs = () => {
    const context = useContext(TabsContext);
    if (context === undefined) {
        throw new Error('useTabs must be used within a TabsProvider');
    }
    return context;
};

export const TabsProvider = ({ children }: TabsProviderProps) => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const tabsRef = useRef<HTMLElement[]>([]);

    const value = {
        activeTab,
        setActiveTab,
        tabsRef,
    };

    return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

