import React, {ReactNode} from "react";
import classNames from "classnames";

import s from './Badge.module.scss'
import {PriorityEnum} from "@/api/tasks/interfaces/priority.enum";
import getContrastColor from "@/utils/getContrastColor";

type Props = {
    children: ReactNode,
    priority?: PriorityEnum,
    color?: string
};

export default function Badge({children, priority, color}: Props) {
    return (
        <div className={classNames({
            [s.badge]: true,
            [s[`badge_${priority}`]]: !!priority
        })} style={color ? {backgroundColor: color, color: getContrastColor(color)} : {}}>
            {children}
        </div>
    );
}
