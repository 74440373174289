import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TimerDto} from "@/api/timer/dto/timer.dto";

interface TimerState {
    isRunning: boolean
    timer: TimerDto | undefined
}

const initialState: TimerState = {
    isRunning: false,
    timer: undefined,
}

export const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        setTimer: (state, action: PayloadAction<TimerDto | undefined>) => {
            state.timer = action.payload
        },
        setRunning: (state, action: PayloadAction<boolean>) => {
            state.isRunning = action.payload
        },
    },
})

export const {setTimer, setRunning} = timerSlice.actions

export default timerSlice.reducer
