import React, {Fragment, useEffect, useState} from 'react';

import PageHeader from "@/components/PageHeader";
import ReportsHeaderItem from "@/pages/ReportsPage/components/ReportsHeaderItem";

import s from './ReportsPage.module.scss';
import useFetchReports from "@hooks/query/reports/useFetchReports";
import useFetchProfile from "@hooks/query/profile/useFetchProfile";
import {SelectCalendar} from "@/UI/Select";
import {DateTime} from "luxon";

export default function ReportsPage() {
    const {data: profile} = useFetchProfile()
    const [date, setDate] = useState<DateTime | null | undefined>(null)
    const {data: reports, refetch} = useFetchReports({date: date ? date.toString() : undefined})

    useEffect(() => {
        refetch().catch()
    }, [date])

    return (
        <div className={s.container}>
            <PageHeader title="pages.reports">
                <SelectCalendar value={date} setValue={setDate} canSelectTime={false} />
            </PageHeader>
            <div className={s.container__content}>
                {profile && reports && reports.map((report, index) => (
                    <Fragment key={index}>
                        <ReportsHeaderItem report={report} profile={profile} date={date}/>
                    </Fragment>
                ))}
            </div>
        </div>
    )
}