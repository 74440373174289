import {useQuery} from "@tanstack/react-query";
import {TimerDto} from "@/api/timer/dto/timer.dto";
import TimerMethods from "@/api/timer/TimerMethods";

const useFetchCurrentTimer = () => {
    return useQuery<TimerDto, null>({
        queryKey: ["current-timer"],
        queryFn: async () => {
            return TimerMethods.getCurrent();
        },
    });
};

export default useFetchCurrentTimer;
