import * as Yup from 'yup'
import { IConfirmForm, ILoginForm, IProfileForm, IStatusesForm } from "@/utils/Forms";
import { isEmail, isHexColor, isStrongPassword } from "validator";
import { PriorityEnum } from '@/api/tasks/interfaces/priority.enum';
import { CreateUserDto } from '@/api/users/dto/create.user.dto';
import { RightsEnum } from '@/api/users/interfaces/rights.enum';
import { UpdateUserDto } from '@/api/users/dto/update.user.dto';
import { CreateUpdateTaskDto } from '@/api/tasks/dto/create-update.task.dto';

export const SchemaLogin: Yup.ObjectSchema<ILoginForm> = Yup.object({
    email: Yup.string().test('is-email', 'inputs.errors.isEmail', (value) => {
        return isEmail(String(value));
    }).required('inputs.errors.required'),
    password: Yup.string().required('inputs.errors.required'),
})

export const SchemaConfirm: Yup.ObjectSchema<IConfirmForm> = Yup.object({
    password: Yup.string().required('inputs.errors.required').test('is-strong-password', 'inputs.errors.isStrongPassword', (value) => {
        return isStrongPassword(value);
    }),
    confirmPassword: Yup.string().required('inputs.errors.required').oneOf([Yup.ref('password'), ""], 'inputs.errors.passwordsMustMatch'),
})

export const SchemaStatuses: Yup.ObjectSchema<IStatusesForm> = Yup.object({
    title: Yup.string().required('inputs.errors.required'),
    color: Yup.string().required('inputs.errors.required').test('is-hex', 'inputs.errors.isHex', (value) => {
        return isHexColor(value);
    }),
})

export const SchemaTaskCreateOrUpdate: Yup.ObjectSchema<CreateUpdateTaskDto> = Yup.object({
    title: Yup.string().required('inputs.errors.required'),
    index: Yup.number().optional(),
    statuses: Yup.number().optional(),
    description: Yup.string().optional().nullable(),
    start_date: Yup.string().optional().nullable(),
    plan_time_seconds: Yup.number().optional().nullable(),
    tags: Yup.array().of(Yup.string().defined()).optional().nullable(),
    deadline: Yup.string().optional().nullable(),
    priority: Yup.string().oneOf(Object.values(PriorityEnum), 'inputs.errors.oneOfOption').optional(),
    responsible: Yup.array().of(Yup.number().defined()).optional(),
})

export const SchemaUserCreate: Yup.ObjectSchema<CreateUserDto> = Yup.object({
    email: Yup.string().test('is-email', 'inputs.errors.isEmail', (value) => {
        return isEmail(String(value));
    }).required('inputs.errors.required'),
    name: Yup.string().required('inputs.errors.required'),
    role: Yup.string().required('inputs.errors.required'),
    rights: Yup.string().oneOf(Object.values(RightsEnum), 'inputs.errors.oneOfOption').required('inputs.errors.required'),
    picture: Yup.mixed<Blob>().optional(),
})

export const SchemaUserUpdate: Yup.ObjectSchema<UpdateUserDto> = Yup.object({
    email: Yup.string().test('is-email', 'inputs.errors.isEmail', (value) => {
        return isEmail(String(value));
    }).optional(),
    name: Yup.string().optional(),
    role: Yup.string().optional(),
    rights: Yup.string().oneOf(Object.values(RightsEnum), 'inputs.errors.oneOfOption').optional(),
    picture: Yup.mixed<Blob | string>().optional(),
})

export const SchemaUserProfile: Yup.ObjectSchema<IProfileForm> = Yup.object({
    name: Yup.string().optional(),
    picture: Yup.mixed<Blob>().optional(),
    password: Yup.string().optional().test('is-strong-password', 'inputs.errors.isStrongPassword', function (value) {
        if (value && value.trim() !== '') {
            return isStrongPassword(value)
        }
        else return true
    }),
    confirmPassword: Yup.string().optional().test('is-confirm-password', 'inputs.errors.passwordsMustMatch', function (value, context) {
        const password = context.parent.password as string | undefined;
        if (password) {
            if (value === password) {
                return true
            }
            else return false
        }
        else return true
    }),
});