import React, {useContext, useMemo, useState} from 'react';

import Timekeeper from "@/components/Timekeeper";
import Avatar from "@/UI/Avatar";

import {useAppSelector} from "@hooks/store";
import classNames from "classnames";

import s from './LayoutHeader.module.scss'
import {ModalContext} from '@/providers/ModalProvider';
import useFetchProfile from "@hooks/query/profile/useFetchProfile";

export default function LayoutHeader() {
    const [openTask, setOpenTask] = useState(false);
    const {data: profile} = useFetchProfile()
    const {timer, isRunning} = useAppSelector((state) => state.timer)

    const {modals, open, close} = useContext(ModalContext)

    const TimerMemo = useMemo(() => {
        return <div onClick={() => setOpenTask(!openTask)} className={classNames({
            [s.header__container__content__timer]: true,
            [s.header__container__content__timer_open]: openTask
        })}>
            <Timekeeper taskId={timer?.task?.id ? timer.task.id : undefined} seconds={timer?.seconds || 0}/>
        </div>
    }, [timer, isRunning, openTask])

    const headerContent = useMemo(() => {
        return (
            <div className={s.header__container__content}>
                <div className={s.header__container__content__timeTracker}>
                    {TimerMemo}
                </div>
                {profile && (
                    <div className={s.header__container__content__user} onClick={() => open({key: 'UserProfileModal'})}>
                        <div className={s.header__container__content__user__avatar}>
                            <Avatar name={profile.name} picture={profile.picture}/>
                        </div>
                        <div className={s.header__container__content__user__info}>{profile.email}</div>
                    </div>
                )}
            </div>
        )
    }, [profile, timer, isRunning, openTask, modals])

    return (
        <header className={s.header}>
            <div className={s.header__container}>{headerContent}</div>
        </header>
    )
}
