import React, {ReactNode} from 'react';

import s from './LayoutContent.module.scss'

type LayoutContentProps = {
  children: ReactNode
}

export default function LayoutContent({children}: LayoutContentProps) {
  return (
    <div className={s.layoutContent}>
      <div className={s.layoutContent__wrapper}>
        {children}
      </div>
    </div>
  )
}