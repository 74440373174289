import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from 'react';

import classNames from "classnames";

import Table from "@/components/Table";

import UserRowInfo from "@/pages/ReportsPage/components/UserRowInfo";
import s from './ReportsHeaderItem.module.scss'
import TableRow from "@/components/Table/components/TableRow";
import UserCard from "@/components/UserCard";
import Badge from "@/UI/Badge";
import {DateTime, Duration} from "luxon";
import {ReportsDto} from "@/api/reports/dto/reports.dto";
import {UserDto} from "@/api/users/dto/user.dto";
import TableColumn from "@/components/Table/components/TableColumn/TableColumn";
import {ITableDataHeader} from "@/components/Table/Table";
import {UpdateTaskForm} from "@/components/forms/UpdateTaskForm";
import {Modal} from "@/components/Modal";
import {ModalContext} from "@/providers/ModalProvider";
import {UpdateUserForm} from "@/components/forms/UpdateUserForm";

type ReportsHeaderItemProps = {
    report: ReportsDto
    profile: UserDto
    date: DateTime | null | undefined
}

export default function ReportsHeaderItem({report, profile, date}: ReportsHeaderItemProps) {
    const tableGrid = ['minmax(300px, 1fr)', 'minmax(200px, 300px)', 'minmax(160px, 160px)', 'minmax(100px, 200px)', 'minmax(100px, 200px)']
    const headerData: ITableDataHeader[] = [
        {title: 'Task'},
        {title: 'Created'},
        {title: 'Status'},
        {title: 'Plan. time', position: 'flex-end'},
        {title: 'Fact. time', position: 'flex-end'},
    ];
    const [isUserRowOpen, setIsUserRowOpen] = useState<boolean>(profile.id !== report.user.id)
    const {open} = useContext(ModalContext)

    const getTime = useCallback((seconds?: number | null) => {
        if (seconds === undefined || seconds === null) return '00:00:00'
        return Duration.fromObject({seconds: seconds}).toFormat("hh:mm:ss")
    }, [report])
    const refTable = useRef<HTMLDivElement>(null)

    const toggleUserRow = useCallback(() => {
        setIsUserRowOpen(!isUserRowOpen)
    }, [isUserRowOpen, refTable.current])

    useEffect(() => {
        if (refTable.current) {
            if (isUserRowOpen) {
                refTable.current.style.height = "0px"
            } else {
                const height = refTable.current.scrollHeight
                refTable.current.style.height = height + "px"
            }
        }
    }, [isUserRowOpen, refTable.current, date, report])

    return (
        <div className={s.reportsHeaderItem}>
            <div className={s.reportsHeaderItem__userRow}>
                <UserRowInfo onClick={toggleUserRow} isUserRowOpen={!isUserRowOpen} user={report.user}
                             overallTime={getTime(report.total_fact_time_seconds)}/>
            </div>

            <div className={classNames({
                [s.reportsHeaderItem__content]: true
            })} ref={refTable}>
                <div className={s.reportsHeaderItem__content__table}>
                    <Table dataHeaders={headerData} gridWidthSize={tableGrid}>
                        {report.tasks && report.tasks.map((item, rowIndex) => (
                            <Fragment key={rowIndex}>
                                <TableRow gridWidthSize={tableGrid}>
                                    <TableColumn onClick={() => open({key: `UpdateTaskModal_${item.id}`})}
                                                 isClickable={true} title={item.title}>
                                        <div>{item.title}</div>
                                    </TableColumn>
                                    <TableColumn onClick={() => {
                                        if (profile.rights === 'ADMIN') {
                                            open({
                                                key: `UpdateUserModal_${item.creator.id}`
                                            })
                                        }
                                    }}
                                                 isClickable={profile.rights === 'ADMIN'}>
                                        <UserCard name={item.creator.name} pictureUrl={item.creator.picture}/>
                                    </TableColumn>
                                    <TableColumn>
                                        <Badge color={item.statuses.color}>{item.statuses.title}</Badge>
                                    </TableColumn>
                                    <TableColumn position='flex-end'>
                                        <div>{getTime(item?.plan_time_seconds)}</div>
                                    </TableColumn>
                                    <TableColumn position='flex-end'>
                                        <div>{getTime(item?.timer?.total_seconds)}</div>
                                    </TableColumn>
                                </TableRow>
                                <Modal name={`UpdateTaskModal_${item.id}`}>
                                    <UpdateTaskForm task={item} status={item.statuses}/>
                                </Modal>
                                <Modal name={`UpdateUserModal_${item.creator.id}`}>
                                    <UpdateUserForm user={item.creator}/>
                                </Modal>
                            </Fragment>
                        ))}
                        <TableRow gridWidthSize={tableGrid}>
                            <TableColumn/>
                            <TableColumn/>
                            <TableColumn/>
                            <TableColumn position='flex-end'>
                                <div>{getTime(report.total_plan_time_seconds)}</div>
                            </TableColumn>
                            <TableColumn position='flex-end'>
                                <div>{getTime(report.total_fact_time_seconds)}</div>
                            </TableColumn>
                        </TableRow>
                    </Table>
                </div>
            </div>
        </div>
    )
}