import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getCookie, setCookie, deleteCookie} from "cookies-next";

interface AuthState {
  token: string | undefined
}

const initialState: AuthState = {
  token: getCookie('token'),
}

const removeAuthCookie = (name: string) => {
  deleteCookie(name);
};

const setAuthCookie = (token: string, name: string) => {
  setCookie(name, token, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
    // more security options here
    // sameSite: 'strict',
    // httpOnly: true,
    // secure: process.env.NODE_ENV === 'production',
  });
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
      setAuthCookie(action.payload, 'token')
    },
    removeToken: (state) => {
      state.token = undefined
      removeAuthCookie('token')
    },
  },
})

export const {setToken, removeToken} = AuthSlice.actions

export default AuthSlice.reducer
