import {useContext} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {Outlet} from "react-router";
import {AuthContext} from "@/providers/AuthProvider";

export const NotPrivateRoute = () => {
  const {isAuthenticated} = useContext(AuthContext);
  const location = useLocation();

  return (!isAuthenticated ? <Outlet/> : <Navigate to="/" state={{from: location}} replace/>);
}