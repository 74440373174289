import React, {useContext} from 'react';

import Avatar from "@/UI/Avatar";
import Timekeeper from "@/components/Timekeeper";
import Date from "@/components/Date";

import {TaskDto} from "@/api/tasks/dto/task.dto";
import s from './TaskItem.module.scss'
import Ripple from "@/components/Ripple";
import {ModalContext} from "@/providers/ModalProvider";
import {useNavigate} from "react-router-dom";

type TaskItemProps = {
    task: TaskDto
}

export default function TaskItem({task}: TaskItemProps) {
    const navigation = useNavigate();
    const {open} = useContext(ModalContext)

    return (
        <>
            <button className={s.item} onClick={() => {
                navigation('/')
                open({key: `UpdateTaskModal_${task.id}`})
            }}>
                <div className={s.item__content}>
                    <div className={s.item__content__header}>
                        <div className={s.item__content__header__avatar}>
                            <div className={s.item__content__header__avatar__img}>
                                <Avatar name={task.creator.name} picture={task.creator.picture}/>
                            </div>
                        </div>
                        <div className={s.item__content__header__taskName}>
                            {task.title}
                        </div>
                    </div>
                    <div className={s.item__content__footer}>
                        <div className={s.item__content__footer__timekeeper}>
                            <Timekeeper taskId={task.id} seconds={task.timer?.total_seconds || 0}/>
                        </div>
                        <Date time={task.deadline} className={s.item__content__footer__date}/>
                    </div>
                </div>
                <Ripple duration={850} color="#ffffff"/>
            </button>
        </>
    )
}
