import { useEffect, useState } from 'react';
import Tag from "@/UI/Tag";
import s from './TagCreator.module.scss';
import {useTranslation} from "react-i18next";

type TagCreatorProps = {
    onChange?: (tags: string[]) => void;
    value?: string[] | null;
};

export default function TagCreator({ value = [], onChange }: TagCreatorProps) {
    const [tags, setTags] = useState<string[]>(value || []);


    const {t} = useTranslation()

    useEffect(() => {
        setTags(value || []);
    }, [value]);

    const addTag = (tag: string) => {
        if (tag && !tags.includes(tag)) {
            const updatedTags = [...tags, tag];
            setTags(updatedTags);
            if (onChange) {
                onChange(updatedTags);
            }
        }
    }

    const removeTag = (tagToRemove: string) => {
        const updatedTags = tags.filter(tag => tag !== tagToRemove);
        setTags(updatedTags);
        if (onChange) onChange(updatedTags);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === '') {
            event.preventDefault();
            addTag(inputValue.trim());
            setInputValue('');
        }
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLInputElement>) => {
        if(inputValue !== '') {
            if (event.button === 0) {
                event.preventDefault();
                addTag(inputValue.trim());
                setInputValue('');
            }
        }
    };

    const [inputValue, setInputValue] = useState('');

    return (
        <div className={s.tagCreator}>
            <input
                className={s.tagCreator_input}
                placeholder={t('inputs.label.tag')}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                onMouseDown={handleMouseDown}
            />
            {tags.map(tag => (
                <Tag key={tag} type="taskCreation" onDelete={() => removeTag(tag)}>
                    {tag}
                </Tag>
            ))}
        </div>
    );
}
