import { useState, useEffect, useContext } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { SchemaTaskCreateOrUpdate } from '@/utils/config/yupShemes';
import { CreateTaskDto } from '@/api/tasks/dto/create.task.dto';
import { PriorityEnum } from '@/api/tasks/interfaces/priority.enum';
import { CreateUpdateTaskDto } from '@/api/tasks/dto/create-update.task.dto';

import useFetchStatusesTasks from '@/hooks/query/statuses/useFetchStatusesTasks';
import useFetchUsers from '@/hooks/query/users/useFetchUsers';
import useMutationTaskCreate from '@/hooks/mutations/tasks/useMutationTaskCreate';

import { ModalContext } from '@/providers/ModalProvider';

import Badge from '@/UI/Badge';
import Button from '@/UI/Button';
import Input from '@/UI/Input';
import { Select, SelectCalendar, SelectUser } from '@/UI/Select';
import { TextArea } from '@/UI/TextArea';
import Toasts from '@/utils/Toasts';
import { ModalForm } from '@/components/Modal/components/ModalForm';
import { ModalWrapper } from '@/components/Modal/components/ModalWrapper';
import TagCreator from '@/UI/TagCreator';

type CreateTaskFormProps = {
	statusesId?: number,
}

export const CreateTaskForm = ({ statusesId }: CreateTaskFormProps) => {
	const defaultValues = statusesId ? { statuses: statusesId } : {};

	const { register, formState: { errors, isValid }, handleSubmit, getValues, setValue, reset } = useForm<CreateUpdateTaskDto>({
		resolver: yupResolver(SchemaTaskCreateOrUpdate),
		mode: 'all',
		defaultValues: defaultValues
	});

	const { closeAll } = useContext(ModalContext);

	const defaultChangeValueOptions = { shouldTouch: true, shouldDirty: true, shouldValidate: true };

	const { data: statuses } = useFetchStatusesTasks();
	const { data: users } = useFetchUsers();

	const createTask = useMutationTaskCreate();

	const { t } = useTranslation();

	const [tags, setTags] = useState<string[]>(getValues('tags') || []);

	const submit: SubmitHandler<CreateUpdateTaskDto> = (data) => {
		createTask.mutateAsync({
			body: { ...(data as CreateTaskDto), index: 1, tags }
		})
			.then(() => {
				closeAll();
				Toasts.success(t('alerts.tasks.create.success'));
			})
			.catch(() => {
				Toasts.error(t('alerts.tasks.create.error'));
			});
	};

	useEffect(() => {
		reset({ ...defaultValues, tags }); // Ensure the tags are reset in the form
	}, [statusesId, tags, reset]);

	return (
		<ModalForm onSubmit={handleSubmit(submit)}>
			<ModalWrapper type='header'>
				<Input
					error={errors['title']?.message ? t(errors['title']?.message) : undefined}
					{...register('title')}
					placeholder={t('forms.tasks.placeholder.title')}
				/>
				<Button type='submit' disabled={!isValid}>{t('buttonActions.create')}</Button>
			</ModalWrapper>
			<ModalWrapper type='block'>
				<ModalWrapper type="block_left">
					<Select
						value={
							statuses && getValues('statuses') ?
								{
									value: statuses[statuses.findIndex((el) => el.id === getValues('statuses'))].id,
									optionComponent: statuses[statuses.findIndex((el) => el.id === getValues('statuses'))].title,
									color: statuses[statuses.findIndex((el) => el.id === getValues('statuses'))].color
								}
								: undefined
						}
						setValue={(value) => {
							if (value && value.value) {
								setValue('statuses', Number(value.value), defaultChangeValueOptions);
							}
						}}
						optionsList={statuses?.map((item) => ({
							value: item.id,
							optionComponent: item.title,
							color: item.color
						}))}
					/>
					<TextArea
						label={t('forms.tasks.label.description')}
						error={errors['description']?.message ? t(errors['description']?.message) : undefined}
						{...register('description')}
						placeholder={t('forms.tasks.placeholder.description')}
					/>
				</ModalWrapper>
				<ModalWrapper type="block_right">
					<div>
						<p>{t('forms.tasks.label.start_date')}</p>
						<SelectCalendar
							value={
								getValues('start_date') ?
									DateTime.fromISO(getValues('start_date') as string)
									: undefined
							}
							setValue={(date) => {
								if (date) {
									setValue('start_date', date.toISO() ?? undefined, defaultChangeValueOptions);
								}
							}}
						/>
					</div>
					<div>
						<p>{t('forms.tasks.label.deadline')}</p>
						<SelectCalendar
							value={
								getValues('deadline') ?
									DateTime.fromISO(getValues('deadline') as string)
									: undefined
							}
							setValue={(date) => {
								if (date) {
									setValue('deadline', date.toISO() ?? undefined, defaultChangeValueOptions);
								}
							}}
						/>
					</div>
					<div>
						<p>{t('forms.tasks.label.priority')}</p>
						<Select
							value={
								getValues('priority') ? {
										value: String(getValues('priority')),
										optionComponent: <Badge priority={PriorityEnum[String(getValues('priority')) as keyof typeof PriorityEnum]}>
											{t(`task.priority.${String(getValues('priority'))}`)}
										</Badge>
									}
									: undefined
							}
							setValue={(value) => {
								if (value && value.value) {
									setValue('priority', PriorityEnum[String(value.value) as keyof typeof PriorityEnum], defaultChangeValueOptions);
								}
							}}
							optionsList={Object.values(PriorityEnum).map((item) => ({
								value: item,
								optionComponent: <Badge priority={PriorityEnum[item as keyof typeof PriorityEnum]}>
									{t(`task.priority.${item}`)}
								</Badge>
							}))}
						/>
					</div>
					<div>
						<p>{t('inputs.placeholder.tags')}</p>
						<SelectUser
							value={
								getValues('responsible') ?
									users?.filter((el) => (getValues('responsible') as number[]).some((item) => item === el.id))
									: undefined
							}
							optionsList={users?.map((item) => item)}
							setValue={(value) => {
								if (value) {
									setValue('responsible', value.map((item) => item.id), defaultChangeValueOptions);
								}
							}}
						/>
					</div>
					<div>
						<TagCreator
							value={tags}
							onChange={(newTags) => {
								setTags(newTags);
								setValue('tags', newTags, defaultChangeValueOptions);
							}}
						/>
					</div>
				</ModalWrapper>
			</ModalWrapper>
		</ModalForm>
	);
};
