import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';

import { SchemaUserCreate } from '@/utils/config/yupShemes';
import { CreateUserDto } from '@/api/users/dto/create.user.dto';
import { RightsEnum } from '@/api/users/interfaces/rights.enum';

import useMutationUserCreate from '@/hooks/mutations/users/useMutationUserCreate';

import { ModalContext } from '@/providers/ModalProvider';

import Button from '@/UI/Button';
import Input from '@/UI/Input';
import { Select } from '@/UI/Select';
import Toasts from '@/utils/Toasts';
import { ModalForm } from '@/components/Modal/components/ModalForm';
import { ModalWrapper } from '@/components/Modal/components/ModalWrapper';
import { CropperInput } from '@/UI/CropperInput';

type CreateUserFormProps = {
    
}

export const CreateUserForm = ({ }: CreateUserFormProps) => {

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        getValues,
        setValue,
        watch
    } = useForm<CreateUserDto>({
        resolver: yupResolver(SchemaUserCreate),
        mode: 'all',
    });

    const {closeAll} = useContext(ModalContext)

    const defaultChangeValueOptions = { shouldTouch: true, shouldDirty: true, shouldValidate: true }

    const createUser = useMutationUserCreate()

    const { t } = useTranslation()

    const submit = handleSubmit((data: CreateUserDto) => {
        createUser.mutateAsync({
            body: data
        })
            .then((res) => {
                closeAll()
                Toasts.success(t('alerts.tasks.create.success'))
            })
            .catch(() => {
                Toasts.error(t('alerts.tasks.create.error'))
            })
    })

    useEffect(()=>{
        console.log('getValues()', getValues())
    }, [watch()])

    return (
        <ModalForm onSubmit={submit}>
            <ModalWrapper type='header'>
                <p>Create user</p>
                <Button type='submit' disabled={!isValid}>{t('buttonActions.create')}</Button>
            </ModalWrapper>
            <ModalWrapper type='block'>
                <ModalWrapper type="block_left">
                    <CropperInput
                        setImage={(val)=>{
                            if (val) {
                                setValue('picture', val, defaultChangeValueOptions)
                            }
                            else setValue('picture', undefined, defaultChangeValueOptions)
                        }}
                    />
                    <Input
                        error={errors['name']?.message ? t(errors['name']?.message) : undefined}
                        {...register('name')}
                        label={t('inputs.label.name')}
                        placeholder={t('inputs.placeholder.name')}
                    />
                    <Input
                        error={errors['email']?.message ? t(errors['email']?.message) : undefined}
                        {...register('email')}
                        label={t('inputs.label.email')}
                        placeholder={t('inputs.placeholder.email')}
                    />
                    <Input
                        error={errors['role']?.message ? t(errors['role']?.message) : undefined}
                        {...register('role')}
                        label={t('inputs.label.role')}
                        placeholder={t('inputs.placeholder.role')}
                    />
                    <Select
                        value={
                            getValues('rights') ? {
                                value: String(getValues('rights')),
                                optionComponent: t(`user.rights.${String(getValues('rights'))}`)
                            }
                                : undefined
                        }
                        setValue={(value) => {
                            if (value && value.value) {
                                setValue('rights', RightsEnum[(String(value.value) as keyof typeof RightsEnum)], defaultChangeValueOptions)
                            }
                        }}
                        optionsList={Object.values(RightsEnum).map((item) => {
                            return {
                                value: item,
                                optionComponent: t(`user.rights.${item}`)
                            }
                        })}
                    />
                </ModalWrapper>
            </ModalWrapper>
        </ModalForm>
    )
};