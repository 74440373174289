import Api from "@/api/Api";
import {ReportsDto} from "@/api/reports/dto/reports.dto";
import {ReportsOptionsDto} from "@/api/reports/dto/reports-options.dto";

class ReportsMethods extends Api {
    async get(query: ReportsOptionsDto) {
        const {data} = await this.request<ReportsDto[], null>('/reports', {
            method: 'GET',
            params: query,
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }
}

export default new ReportsMethods()