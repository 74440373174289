import React from 'react';

import s from './PopupSelectorItem.module.scss'
import Ripple from "@/components/Ripple";
import {useTranslation} from "react-i18next";

type PopupSelectorItemProps = {
    icon?: React.ReactNode;
    text?: string;
    tooltip?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    children?: React.ReactNode;
}

export default function PopupSelectorItem({
                                              onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
                                              },
                                              children = undefined,
                                              tooltip = '',
                                              text = '',
                                              icon = undefined,
                                          }: PopupSelectorItemProps) {
    const {t} = useTranslation()
    if (children) {
        return <div className={s.item__component}>{children}</div>;
    }

    return (
        <button onClick={onClick} className={s.item}>
            <div className={s.item__defaultCard}>
                <div className={s.item__defaultCard__leftPart}>
                    {icon && <div className={s.item__defaultCard__leftPart__icon}>{icon}</div>}
                    {text && <span className={s.item__defaultCard__leftPart__text}>{t(text)}</span>}
                </div>
                <div className={s.item__defaultCard__rightPart}>
                    {tooltip && <span className={s.item__defaultCard__rightPart__tooltip}>{t(tooltip)}</span>}
                </div>
            </div>
            <Ripple duration={850} color="#ffffff"/>
        </button>
    );
}
