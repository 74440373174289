import React, { useContext, useState } from 'react';

import { Outlet } from "react-router";

import LayoutHeader from "@/layouts/MainLayout/LayoutHeader";
import LayoutAside from "@/layouts/MainLayout/LayoutAside";
import LayoutContent from "@/layouts/MainLayout/LayoutContent";
import { Modal } from '@/components/Modal';

import s from './MainLayout.module.scss';
import { UserProfileForm } from '@/components/forms/UserProfileForm';

export default function MainLayout() {

  return (
    <div className={s.layout}>
      <div className={s.layout__content}>
        <div className={s.layout__content__leftPart}>
          <LayoutAside />
        </div>
        <div className={s.layout__content__rightPart}>
          <div className={s.layout__content__rightPart__header}>
            <LayoutHeader />
          </div>
          <div className={s.layout__content__rightPart__content}>
            <LayoutContent>
              <Outlet />
            </LayoutContent>
          </div>
          <Modal name="UserProfileModal">
            <UserProfileForm/>
          </Modal>
        </div>
      </div>
    </div>
  )
}
