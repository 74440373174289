import React, { ReactNode } from 'react';
import s from './TableRow.module.scss';
import classNames from "classnames";
import Ripple from "@/components/Ripple";

interface TableRowProps {
    children: ReactNode;
    gridWidthSize?: Array<string | number>;
    isClickable?: boolean;
    onClick?: () => void;
}

function TableRow({ children, gridWidthSize = [], isClickable = false, onClick = () => { } }: TableRowProps) {
    return (
        <div className={classNames({
            [s.row]: true,
            [s.usuallyRow]: isClickable,
        })}
             onClick={onClick}
             style={{
                 gridTemplateColumns: gridWidthSize?.join(' '),
             }}
        >
            {React.Children.map(children, (child, index) => (
                <div
                    className={s.titleContainer}
                >
                    {child}
                </div>
            ))}
            {isClickable && <Ripple duration={850} color="#ffffff" />}
        </div>
    );

}

export default TableRow;
