import React from "react";

type Props = {};

export default function Trash({}: Props) {
    return (
        <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <desc>
                Created with Pixso.
            </desc>
            <defs>
                <clipPath id="clip1_874">
                    <rect id="Variant=Trash" width="24.000000" height="24.000000" fill="white" fill-opacity="0"/>
                </clipPath>
            </defs>
            <g clip-path="url(#clip1_874)">
                <path id="path" d="M3 6.38C3 5.9 3.34 5.5 3.77 5.5L6.43 5.5C6.96 5.49 7.43 5.11 7.61 4.54L7.75 4.05C7.82 3.81 7.88 3.6 7.97 3.41C8.31 2.67 8.93 2.16 9.66 2.03C9.84 1.99 10.03 1.99 10.26 2L13.73 2C13.96 1.99 14.15 1.99 14.33 2.03C15.06 2.16 15.68 2.67 16.02 3.41C16.11 3.6 16.17 3.81 16.24 4.05L16.35 4.44C16.53 5.01 17.12 5.49 17.65 5.5L20.22 5.5C20.65 5.5 21 5.9 21 6.38C21 6.87 20.65 7.26 20.22 7.26L3.77 7.26C3.34 7.26 3 6.87 3 6.38Z" fill="#D45E5E" fill-opacity="1.000000" fill-rule="nonzero"/>
                <path id="path" d="M9.42 11.48C9.83 11.43 10.2 11.75 10.24 12.18L10.74 17.45C10.78 17.88 10.48 18.27 10.07 18.31C9.66 18.35 9.29 18.04 9.25 17.6L8.75 12.34C8.71 11.91 9.01 11.52 9.42 11.48Z" fill="#D45E5E" fill-opacity="1.000000" fill-rule="evenodd"/>
                <path id="path" d="M14.57 11.48C14.98 11.52 15.28 11.91 15.24 12.34L14.74 17.6C14.7 18.04 14.33 18.35 13.92 18.31C13.51 18.27 13.21 17.88 13.25 17.45L13.75 12.18C13.79 11.75 14.16 11.43 14.57 11.48Z" fill="#D45E5E" fill-opacity="1.000000" fill-rule="evenodd"/>
                <g opacity="0.500000">
                    <path id="path" d="M11.59 22L12.4 22C15.18 22 16.57 22 17.48 21.11C18.38 20.22 18.48 18.77 18.66 15.86L18.93 11.68C19.03 10.1 19.08 9.31 18.62 8.81C18.17 8.31 17.4 8.31 15.87 8.31L8.12 8.31C6.59 8.31 5.82 8.31 5.37 8.81C4.91 9.31 4.96 10.1 5.06 11.68L5.33 15.86C5.51 18.77 5.61 20.22 6.51 21.11C7.42 22 8.81 22 11.59 22Z" fill="#D45E5E" fill-opacity="1.000000" fill-rule="nonzero"/>
                </g>
            </g>
        </svg>
    )
}
