import React from "react";

export default function ErrorImage() {
	return (
		<svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<clipPath id="clip219_8098">
					<rect id="Variant=Image Error" width="24.000000" height="24.000000" fill="white" fillOpacity="0"/>
				</clipPath>
			</defs>
			<g clipPath="url(#clip219_8098)">
				<path id="path" d="M21.22 12.02L21.2 12C21.2 11.552 21.552 11.2 22 11.2C22.448 11.2 22.8 11.552 22.8 12L22.78 12.02L21.22 12.02ZM11.98 1.22L12 1.2C12.448 1.2 12.8 1.552 12.8 2C12.8 2.448 12.448 2.8 12 2.8L11.98 2.78L11.98 1.22Z" fill="#FFFFFF" fillOpacity="0" fillRule="nonzero"/>
				<path id="path" d="M22 12C22 16.714 22 19.0711 20.5354 20.5355C19.071 22 16.7141 22 12 22C7.28589 22 4.92896 22 3.46436 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92892 3.46436 3.46446C4.92896 2 7.28589 2 12 2" stroke="#FFFFFF" strokeOpacity="1.000000" strokeWidth="1.600000" strokeLinecap="round"/>
				<path id="path" d="M2.52856 13.074L2.52686 13.1022C2.1897 13.3972 1.69287 13.3641 1.39795 13.0269C1.10303 12.6897 1.13599 12.1931 1.47314 11.898L1.50146 11.8999L2.52856 13.074ZM21.5068 17.907L21.5352 17.9055C21.8682 18.2052 21.8943 18.7023 21.5947 19.0353C21.2949 19.3683 20.7979 19.3944 20.4648 19.0948L20.4634 19.0665L21.5068 17.907Z" fill="#FFFFFF" fillOpacity="0" fillRule="nonzero"/>
				<path id="path" d="M2 12.5001L3.75171 10.9675C4.66284 10.1702 6.03638 10.2159 6.89258 11.0721L11.1821 15.3618C11.8694 16.0491 12.9512 16.1428 13.7463 15.5839L14.0447 15.3744C15.1887 14.5702 16.7368 14.6634 17.7766 15.599L21 18.5001" stroke="#FFFFFF" strokeOpacity="1.000000" strokeWidth="1.600000" strokeLinecap="round"/>
				<path id="path" d="M21.4343 1.46262L21.4343 1.43433C21.7512 1.11755 22.249 1.11755 22.5657 1.43434C22.8826 1.75111 22.8826 2.24892 22.5657 2.5657L22.5374 2.5657L21.4343 1.46262ZM16.5657 8.5374L16.5657 8.56569C16.2488 8.88248 15.751 8.88246 15.4343 8.56569C15.1174 8.2489 15.1174 7.7511 15.4343 7.43431L15.4626 7.43431L16.5657 8.5374ZM15.4626 2.56569L15.4343 2.56569C15.1174 2.2489 15.1174 1.7511 15.4343 1.43431C15.7512 1.11752 16.249 1.11754 16.5657 1.43431L16.5657 1.4626L15.4626 2.56569ZM22.5374 7.4343L22.5657 7.4343C22.8823 7.75108 22.8823 8.24889 22.5657 8.56567C22.2488 8.88245 21.751 8.88245 21.4343 8.56566L21.4343 8.53738L22.5374 7.4343Z" fill="#FFFFFF" fillOpacity="0" fillRule="nonzero"/>
				<path id="path" d="M22 2.00002L16 8M16 2L22 7.99998" stroke="#FFFFFF" strokeOpacity="1.000000" strokeWidth="1.600000"/>
			</g>
		</svg>
	);
}

