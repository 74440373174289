import Api from "@/api/Api";
import {TimerDto} from "@/api/timer/dto/timer.dto";
import {UpdateTimerDto} from "@/api/timer/dto/update.timer.dto";

class TimerMethods extends Api {
    async start(taskId: number) {
        const {data} = await this.request<TimerDto, null>(`/timer/start/${taskId}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async stop() {
        const {data} = await this.request<TimerDto, null>(`/timer/stop`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async getCurrent() {
        const {data} = await this.request<TimerDto, null>('/timer', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async get() {
        const {data} = await this.request<TimerDto[], null>('/timer/list', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async getByTaskId(taskId: number) {
        const {data} = await this.request<TimerDto[], null>(`/timer/list/${taskId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async updateById(id: number, body: UpdateTimerDto) {
        const {data} = await this.request<TimerDto, UpdateTimerDto>(`/timer/${id}`, {
            method: 'PUT',
            data: body,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async removeById(id: number) {
        const {data} = await this.request<void, null>(`/timer/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }
}

export default new TimerMethods()