import {useQuery} from "@tanstack/react-query";
import {UserDto} from "@/api/users/dto/user.dto";
import UsersMethods from "@/api/users/UsersMethods";

const useFetchUsers = () => {
    return useQuery<UserDto[], null>({
        queryKey: ["users"],
        queryFn: async () => {
            return UsersMethods.get();
        },
    });
};

export default useFetchUsers;
