import classNames from "classnames";
import React, { Ref, forwardRef, useCallback, useEffect, useRef } from "react";
import { ChangeHandler } from "react-hook-form";

import s from './TextArea.module.scss'

type TextAreaProps = {
    label?: string,
    name: string,
    placeholder?: string,
    error?: string | undefined,
    status?: 'active' | 'disable',
    onChange?: ChangeHandler,
    onBlur?: ChangeHandler,
    onSubmit?: () => void,
    touched?: boolean | undefined,
    ref?: Ref<HTMLTextAreaElement>,
    value?: string | number | readonly string[] | undefined,
};

export const TextArea: React.FC<TextAreaProps> = forwardRef((
    {
        label,
        placeholder,
        name,
        onChange,
        onBlur,
        status = 'active',
        error,
        onSubmit,
        touched,
        value,
    }, ref): JSX.Element => {

    const textareaParentRef = useRef<HTMLDivElement>(null)

    const handleChange = useCallback((event: { target: HTMLTextAreaElement }) => {
        const target = event.target
        if (onChange) {
            onChange(event);
            setCurrentHeight(target)
        }
    }, [onChange])

    const setCurrentHeight = (target: HTMLTextAreaElement) =>{
        target.style.height = "0px";
        target.style.height = target.scrollHeight + "px";
    }

    useEffect(()=>{
        const parentBlock = textareaParentRef.current
        const textareaBlock = parentBlock?.querySelector('textarea')
        if (textareaBlock) {
            return setCurrentHeight(textareaBlock)
        }
    }, [textareaParentRef])

    return (
        <div className={classNames(s.textarea, s[`textarea_${status}`])} ref={textareaParentRef}>
            {label && (
                <label className={s.textarea__label} htmlFor={name}>{label}</label>
            )}
            <textarea
                rows={!value ? 1 : undefined}
                className={classNames(s.textarea__field, error && s.textarea__field_error)}
                readOnly={status === 'disable'}
                name={name}
                value={value}
                id={name}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={onBlur}
                ref={ref}
                onSubmit={onSubmit}
            />
            {error && <p className={s.textarea__error}>{error}</p>}
        </div>
    )
})
