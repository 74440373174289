import React from 'react';

import {useTranslation} from "react-i18next";

import s from './DeadlineNotification.module.scss';
import classNames from "classnames";
import Date from "@/components/Date";

type DeadlineNotification = {
    taskDeadline: string | null | undefined
    deadlineStyle?: string
}

export default function DeadlineNotification({taskDeadline, deadlineStyle = ''}: DeadlineNotification) {


    const {t} = useTranslation()

    return (
        <div className={s.deadlineNotification}>
            <div className={s.deadlineNotification__content}>
                <div className={classNames({
                    [s.deadlineNotification__content__text]: true,
                    [deadlineStyle]: true
                })}>{t('forms.tasks.label.deadline')}</div>
                <Date time={taskDeadline} className={s.deadlineNotification__content__date}/>
            </div>
        </div>
    )
}
