import React, {useContext} from 'react';
import {Droppable} from "react-beautiful-dnd";

import {ModalContext} from '@/providers/ModalProvider';

import KanbanItem from "@/pages/DashboardPage/components/KanbanItem";

import {StatusesDto} from "@/api/statuses/dto/statuses.dto";

import Add from "@/icons/Add";

import s from './KanbanRow.module.scss'
import {CreateTaskForm} from '@/components/forms/CreateTaskForm';
import {Modal} from '@/components/Modal';

interface KanbanRowProps {
    item: StatusesDto;
}

export default function KanbanRow({item}: KanbanRowProps) {

    const {modals, open, close} = useContext(ModalContext)

    return (
        item && (<>
        <div className={s.kanbanRow}>
            <div className={s.kanbanRow__content}>
                <div className={s.kanbanRow__content__header}>
                    <div className={s.kanbanRow__content__header__title} style={{color: item.color}}>
                        {item.title}
                    </div>
                    <div className={s.kanbanRow__content__header__wrapperButton}>
                        <button
                            type="button"
                            className={s.kanbanRow__content__header__wrapperButton__button}
                            onClick={() => open({key: `CreateTaskModal_${item.id}`})}
                        >
                            <Add style={{width: "35px", height: "35px"}}/>
                        </button>
                    </div>
                </div>
                <Droppable droppableId={`${item.title}-${item.id}`}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={s.kanbanRow__content__items}
                        >
                            {item.tasks.map((task, index) => (
                                <KanbanItem key={task.id} task={task} status={item} index={index}/>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
        <Modal name={`CreateTaskModal_${item.id}`}>
            <CreateTaskForm statusesId={item.id}/>
        </Modal>
        </>)
    );
}
