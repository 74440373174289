import React from 'react';
import s from './PopupSelector.module.scss';
import {useOutsideClick} from "@hooks/useOutsideClick";
import {usePopup} from "@/providers/PopupProvider";

type PopupSelectorProps = {
    children: React.ReactNode;
    style?: React.CSSProperties;
    onOutsideClick: () => void; // Добавляем новый проп для обработки клика вне компонента
};

export default function PopupSelector({ children, style, onOutsideClick }: PopupSelectorProps) {
    const { isPopupVisible } = usePopup();

    const ref = useOutsideClick(onOutsideClick);

    const containerClasses = isPopupVisible ? `${s.container} ${s.popupEnter}` : `${s.container} ${s.popupExit}`;

    return (
        <div ref={ref} className={containerClasses} style={style}>
            {children}
        </div>
    );
}
