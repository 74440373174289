import {configureStore} from "@reduxjs/toolkit";
import settingsReducer from "@store/reducers/settingsReducer";
import authReducer from "@store/reducers/authReducer";
import profileReducer from "@store/reducers/profileReducer";
import timerReducer from "@store/reducers/timerReducer";

const store = configureStore({
    reducer: {
        settings: settingsReducer,
        auth: authReducer,
        profile: profileReducer,
        timer: timerReducer,
    },
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export default store;
