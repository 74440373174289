import React from "react";

type Props = {};

export default function CalendarImg({ ...props }: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M6.96006 2C7.37758 2 7.71605 2.30996 7.71605 2.69231V4.08883C8.38663 4.07692 9.13829 4.07692 9.98402 4.07692H14.016C14.8617 4.07692 15.6134 4.07692 16.284 4.08883V2.69231C16.284 2.30996 16.6224 2 17.0399 2C17.4575 2 17.7959 2.30996 17.7959 2.69231V4.15008C19.2468 4.25647 20.1992 4.51758 20.899 5.15838C21.5987 5.79917 21.8838 6.67139 22 8V9H2V8C2.11618 6.67139 2.4013 5.79917 3.10104 5.15838C3.80079 4.51758 4.75323 4.25647 6.20406 4.15008V2.69231C6.20406 2.30996 6.54253 2 6.96006 2Z" fill="white" />
            <path opacity="0.5" d="M21.9999 14V12C21.9999 11.161 21.9872 9.66527 21.9743 9H2.00579C1.99289 9.66527 2.00557 11.161 2.00557 12V14C2.00557 17.7712 2.00557 19.6569 3.17681 20.8284C4.34806 22 6.23314 22 10.0033 22H14.0022C17.7723 22 19.6574 22 20.8287 20.8284C21.9999 19.6569 21.9999 17.7712 21.9999 14Z" fill="white" />
            <path d="M18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5Z" fill="white" />
        </svg>
    );
}

