import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {SchemaUserProfile} from '@/utils/config/yupShemes';
import {IProfileForm} from '@/utils/Forms';

import useMutationUserUpdate from '@/hooks/mutations/users/useMutationUserUpdate';

import {ModalContext} from '@/providers/ModalProvider';

import c from './WrapperForm/WrapperForm.module.scss'

import Button from '@/UI/Button';
import Input from '@/UI/Input';
import Toasts from '@/utils/Toasts';
import {ModalForm} from '@/components/Modal/components/ModalForm';
import {ModalWrapper} from '@/components/Modal/components/ModalWrapper';
import {CropperInput} from '@/UI/CropperInput';
import Exit from "@/icons/Exit";
import useFetchProfile from "@hooks/query/profile/useFetchProfile";
import {useQueryClient} from "@tanstack/react-query";

type CreateUserFormProps = {

}

export const UserProfileForm = ({ }: CreateUserFormProps) => {

    const {data: user } = useFetchProfile()

    const defaultValues = {
        name: user?.name,
        password: undefined,
        picture: user?.picture?? undefined,
        confirmPassword: undefined
    }

    const {
        register,
        formState: { errors, isValid, isDirty, dirtyFields },
        handleSubmit,
        setValue,
        getValues,
        getFieldState,
        reset,
        watch,
        resetField
    } = useForm<IProfileForm>({
        resolver: yupResolver(SchemaUserProfile),
        mode: 'all',
        defaultValues: defaultValues,
    });

    const {closeAll} = useContext(ModalContext)

    const defaultChangeValueOptions = { shouldTouch: true, shouldDirty: true, shouldValidate: true }

    const updateUser = useMutationUserUpdate()

    const queryClient = useQueryClient()

    const { t } = useTranslation()

    const handleLogout = () => {
        document.cookie.split(";").forEach((c) => {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        window.location.reload();
    };

    const submit = handleSubmit((data: IProfileForm) => {
        if (user) {
            updateUser.mutateAsync({
                id: user.id,
                body: {
                    name: data.name,
                    picture: data.picture,
                    password: Boolean(data.password)? data.password : undefined
                }
            })
                .then((res) => {
                    queryClient.invalidateQueries({queryKey: ['profile']})
                    queryClient.refetchQueries({queryKey: ['profile']})
                    closeAll()
                    Toasts.success(t('alerts.tasks.update.success'))
                })
                .catch(() => {
                    Toasts.error(t('alerts.tasks.update.error'))
                })
        }
    })

    useEffect(()=> {
        reset(defaultValues)
    }, [user])


    return (
        <ModalForm onSubmit={submit}>
            <ModalWrapper type='header'>
                <p>User Profile</p>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
					<Button type='submit' disabled={!isDirty || !isValid}>{t('buttonActions.update')}</Button>
					<button onClick={()=>handleLogout()} type='button' className={c.exitButton}>
						<Exit/>
					</button>
				</div>
            </ModalWrapper>
            <ModalWrapper type='block'>
                <ModalWrapper type="block_left">
                    <CropperInput
                        image={user?.picture}
                        setImage={(val)=>{
                            if (val) {
                                setValue('picture', val, defaultChangeValueOptions)
                            }
                            else {
                                setValue('picture', user?.picture?? undefined, defaultChangeValueOptions)
                            }
                        }}
                    />
                    <Input
                        error={errors['name']?.message ? t(errors['name']?.message) : undefined}
                        {...register('name')}
                        label={t('inputs.label.name')}
                        placeholder={t('inputs.placeholder.name')}
                    />
                    <Input
                        error={errors['password']?.message ? t(errors['password']?.message) : undefined}
                        {...register('password')}
                        label={t('inputs.label.password')}
                        placeholder={t('inputs.placeholder.password')}
                    />
                    <Input
                        error={errors['confirmPassword']?.message ? t(errors['confirmPassword']?.message) : undefined}
                        {...register('confirmPassword')}
                        label={t('inputs.label.confirmPassword')}
                        placeholder={t('inputs.placeholder.confirmPassword')}
                    />
                </ModalWrapper>
            </ModalWrapper>
        </ModalForm>
    )
};