import {useMutation} from "@tanstack/react-query";
import {queryClient} from "@/App";
import {CreateUserDto} from "@/api/users/dto/create.user.dto";
import usersMethods from "@/api/users/UsersMethods";
import {UserDto} from "@/api/users/dto/user.dto";

const useMutationUserCreate = () => {
    return useMutation<UserDto, Error, { body: CreateUserDto }>({
        mutationFn: async ({body}) => {
            return await usersMethods.create(body);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["users"]});
        },
    })
};

export default useMutationUserCreate;
