import React from "react";

type Props = {};

export default function Dots({ }: Props) {
    return (
        <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <desc>
                Created with Pixso.
            </desc>
            <defs>
                <clipPath id="clip1_903">
                    <rect id="Variant=Dots" width="24.000000" height="24.000000" fill="white" fill-opacity="0"/>
                </clipPath>
            </defs>
            <g clip-path="url(#clip1_903)">
                <path id="Vector" d="M4 14C5.1 14 6 13.1 6 12C6 10.89 5.1 10 4 10C2.89 10 2 10.89 2 12C2 13.1 2.89 14 4 14Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
                <path id="Vector" d="M12 14C13.1 14 14 13.1 14 12C14 10.89 13.1 10 12 10C10.89 10 10 10.89 10 12C10 13.1 10.89 14 12 14Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
                <path id="Vector" d="M20 14C21.1 14 22 13.1 22 12C22 10.89 21.1 10 20 10C18.89 10 18 10.89 18 12C18 13.1 18.89 14 20 14Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
            </g>
        </svg>
    );
}
