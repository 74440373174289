import {UserDto} from "@/api/users/dto/user.dto";
import Api from "@/api/Api";

class ProfileMethods extends Api {
  async get() {
    const {data} = await this.request<UserDto, null>('/profile', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })

    return data
  }
}

export default new ProfileMethods()