import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SettingsSection from '@/pages/SettingsPage/components/SettingsSection/SettingsSection';
import SettingsStatuses from '@/pages/SettingsPage/components/SettingsStatuses/SettingsStatuses';
import PageHeader from '@/components/PageHeader';
import { Select, SelectOption } from '@/UI/Select/Select';
import { setLanguage } from '@store/reducers/settingsReducer';
import { RightsEnum } from '@/api/users/interfaces/rights.enum';
import useFetchStatusesTasks from '@hooks/query/statuses/useFetchStatusesTasks';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import Toasts from '@/utils/Toasts';

import s from './SettingsPage.module.scss';

export default function SettingsPage() {
    const profile = useAppSelector((state) => state.profile.profile);
    const language = useAppSelector((state) => state.settings.language);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { data, isSuccess, isLoading } = useFetchStatusesTasks();

    const selectOptions: SelectOption[] = [
        { value: 'en', optionComponent: 'English' },
        { value: 'ru', optionComponent: 'Русский' },
    ];

    const newDefaultValue: SelectOption = {
        value: language,
        optionComponent: language === 'en' ? 'English' : 'Русский',
    };

    const handleLanguageChange = useCallback(
        (option: SelectOption | undefined) => {
            if (option) {
                dispatch(setLanguage(String(option.value)));
                Toasts.success(t('alerts.updateSettings.success'));
            }
        },
        [dispatch],
    );


    return (
        <div className={s.container}>
            <PageHeader title={'pages.settings.title'} />
            <div className={s.container__content}>
                <SettingsSection title={t('pages.settings.general.title')}>
                    <Select
                        optionsList={selectOptions}
                        value={newDefaultValue}
                        setValue={handleLanguageChange}
                    />
                </SettingsSection>
                {profile && profile.rights === RightsEnum.ADMIN && (
                    <SettingsSection title={t('pages.settings.tasks.title')}>
                        {data && <SettingsStatuses statuses={data} />}
                    </SettingsSection>
                )}
            </div>
        </div>
    );
}
