import {useMutation} from "@tanstack/react-query";
import statusesMethods from "@/api/statuses/StatusesMethods";
import {queryClient} from "@/App";
import {UpdateIndexStatusesDto} from "@/api/statuses/dto/update-index.statuses.dto";

const useMutationStatusesUpdateIndexes = () => {
    return useMutation<void, Error, { body: UpdateIndexStatusesDto[] }>({
        mutationFn: async ({body}) => {
            return await statusesMethods.updateIndexes(body);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ["statuses-task"]});
            queryClient.invalidateQueries({queryKey: ["statuses"]});
        },
    })
};

export default useMutationStatusesUpdateIndexes;
