import {useMutation} from "@tanstack/react-query";
import statusesMethods from "@/api/statuses/StatusesMethods";
import {UpdateStatusesDto} from "@/api/statuses/dto/update.statuses.dto";
import {queryClient} from "@/App";

const useMutationStatusesUpdate = () => {
    return useMutation<UpdateStatusesDto, Error, { id: number; body: UpdateStatusesDto }>({
        mutationFn: async ({id, body}) => {
            return await statusesMethods.updateById(id, body);
        },
        onSuccess: (data, {id}) => {
            queryClient.invalidateQueries({queryKey: ["statuses-task"]});
            queryClient.invalidateQueries({queryKey: ["statuses"]});
        },
    })
};

export default useMutationStatusesUpdate;
