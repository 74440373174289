import {useTranslation} from "react-i18next";
import WrapperForm from "@/components/forms/WrapperForm/WrapperForm";
import ConfirmForm from "@/components/forms/ConfirmForm";

export default function ConfirmPage() {
  const {t} = useTranslation();

  return (
    <WrapperForm title={t('forms.confirm')}>
      <ConfirmForm/>
    </WrapperForm>
  )
}