import {ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

import {useOutsideClick} from '@/hooks/useOutsideClick';

import s from './SelectActions.module.scss'

type Props = {
    optionsList: SelectActionOption[],
    children: ReactNode
};

export type SelectActionOption = {
    value: string | number,
    optionComponent?: {
        icon: ReactNode,
        text: string,
        action: () => void
    },
}

export const SelectActions = ({optionsList, children}: Props) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const {t} = useTranslation();

    const selectRef = useOutsideClick(() => setIsOpen(false))


    const handleSelect = (selectValue: SelectActionOption) => {
        selectValue.optionComponent?.action()
        setIsOpen(false);
    }

    return (
        <div className={s.select} ref={selectRef}>
            <div className={classNames(s.select__block, isOpen && s.select__block_open)}
                 onClick={() => setIsOpen(!isOpen)}>
                {children}
            </div>
            <div className={classNames(s.select__options, isOpen && s.select__options_open)}>
                <ul className={s.select__options__wrapper}>
                    {optionsList?.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className={classNames({
                                    [s.select__options__item]: true,
                                })}
                                onClick={() => {
                                    handleSelect(item)
                                }}
                            >
                                <span className={s.select__options__item__icon}>
                                    {item.optionComponent && item.optionComponent.icon}
                                </span>
                                <span className={s.select__options__item__text}>
                                    {item.optionComponent && t(`${item.optionComponent.text}`)}
                                </span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}
