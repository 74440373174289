import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useContext, useEffect} from 'react';

import {SchemaUserUpdate} from '@/utils/config/yupShemes';

import {RightsEnum} from '@/api/users/interfaces/rights.enum';
import {UpdateUserDto} from '@/api/users/dto/update.user.dto';
import {UserDto} from '@/api/users/dto/user.dto';

import useMutationUserUpdate from '@/hooks/mutations/users/useMutationUserUpdate';
import useMutationUserRemove from '@/hooks/mutations/users/useMutationUserRemove';

import {ModalContext} from '@/providers/ModalProvider';

import Button from '@/UI/Button';
import Input from '@/UI/Input';
import {Select} from '@/UI/Select';
import Toasts from '@/utils/Toasts';
import {ModalForm} from '@/components/Modal/components/ModalForm';
import {ModalWrapper} from '@/components/Modal/components/ModalWrapper';
import {CropperInput} from '@/UI/CropperInput';

import Trash from '@/icons/Trash';
import {UserShortDto} from "@/api/users/dto/user-short.dto";
import ConfirmTitle from "@/components/Confirm/components/ConfirmTitle/ConfirmTitle";
import ConfirmList from "@/components/Confirm/components/ConfirmList/ConfirmList";
import useConfirm from "@hooks/useConfirm";

type UpdateUserFormProps = {
    user: UserDto | UserShortDto
}

export const UpdateUserForm = ({user}: UpdateUserFormProps) => {
    const {confirm} = useConfirm()

    const {
        register,
        formState: {errors, isValid, isDirty},
        handleSubmit,
        getValues,
        setValue,
        reset
    } = useForm<UpdateUserDto>({
        resolver: yupResolver(SchemaUserUpdate),
        mode: 'all',
        defaultValues: {
            ...user
        }
    });

    const {closeAll} = useContext(ModalContext)

    const defaultChangeValueOptions = {shouldTouch: true, shouldDirty: true, shouldValidate: true}

    const updateUser = useMutationUserUpdate()
    const removeUser = useMutationUserRemove()

    const {t} = useTranslation()

    const remove = async () => {
        const result = await confirm(<>
            <ConfirmTitle>Are you sure you want to delete it?</ConfirmTitle>
            <ConfirmList items={[
                'This action cannot be undone',
                'User-related items are also deleted. For example, timed out or created tasks'
            ]} className={'color-error'}/>
        </>)

        if (result) {
            removeUser.mutateAsync({
                id: user.id
            })
                .then(() => {
                    closeAll()
                    Toasts.success(t('alerts.tasks.remove.success'))
                })
                .catch(() => {
                    Toasts.error(t('alerts.tasks.remove.error'))
                })
        }
    }

    const submit = handleSubmit((data: UpdateUserDto) => {
        updateUser.mutateAsync({
            id: user.id,
            body: data
        })
            .then((res) => {
                closeAll()
                Toasts.success(t('alerts.tasks.update.success'))
            })
            .catch(() => {
                Toasts.error(t('alerts.tasks.update.error'))
            })
    })

    useEffect(() => {
        reset({...user})
    }, [user])

    return (
        <ModalForm onSubmit={submit}>
            <ModalWrapper type='header'>
                <p>User Profile</p>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <Button type='submit' disabled={!isDirty || !isValid}>{t('buttonActions.update')}</Button>
                    <button onClick={() => remove()} type='button'>
                        <Trash/>
                    </button>
                </div>
            </ModalWrapper>
            <ModalWrapper type='block'>
                <ModalWrapper type="block_left">
                    <CropperInput
                        image={user.picture}
                        setImage={(val) => {
                            if (val) {
                                setValue('picture', val, defaultChangeValueOptions)
                            } else setValue('picture', user.picture, defaultChangeValueOptions)
                        }}
                    />
                    <Input
                        error={errors['name']?.message ? t(errors['name']?.message) : undefined}
                        {...register('name')}
                        label={t('inputs.label.name')}
                        placeholder={t('inputs.placeholder.name')}
                    />
                    <Input
                        error={errors['email']?.message ? t(errors['email']?.message) : undefined}
                        {...register('email')}
                        label={t('inputs.label.email')}
                        placeholder={t('inputs.placeholder.email')}
                    />
                    <Input
                        error={errors['role']?.message ? t(errors['role']?.message) : undefined}
                        {...register('role')}
                        label={t('inputs.label.role')}
                        placeholder={t('inputs.placeholder.role')}
                    />
                    <Select
                        value={
                            getValues('rights') ? {
                                    value: String(getValues('rights')),
                                    optionComponent: t(`user.rights.${String(getValues('rights'))}`)
                                }
                                : undefined
                        }
                        setValue={(value) => {
                            if (value && value.value) {
                                setValue('rights', RightsEnum[(String(value.value) as keyof typeof RightsEnum)], defaultChangeValueOptions)
                            }
                        }}
                        optionsList={Object.values(RightsEnum).map((item) => {
                            return {
                                value: item,
                                optionComponent: t(`user.rights.${item}`)
                            }
                        })}
                    />
                </ModalWrapper>
            </ModalWrapper>
        </ModalForm>
    )
};