import React from "react";

type Props = {};

export default function Success({}: Props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip26_4222">
          <rect id="Variant=Check" width="24.000000" height="24.000000" fill="white" fillOpacity="0"/>
        </clipPath>
      </defs>
      <rect id="Variant=Check" width="24.000000" height="24.000000" fill="#FFFFFF" fillOpacity="0"/>
      <g clipPath="url(#clip26_4222)">
        <g opacity="0.500000">
          <path id="path"
                d="M3.37744 5.08252C3 5.62012 3 7.21924 3 10.4165L3 11.9912C3 17.6294 7.23877 20.3657 9.89844 21.5273C10.6201 21.8423 10.9805 22 12 22C13.0195 22 13.3799 21.8423 14.1016 21.5273C16.7612 20.3657 21 17.6294 21 11.9912L21 10.4165C21 7.21924 21 5.62012 20.6226 5.08252C20.2451 4.54443 18.7417 4.02979 15.7349 3.00098L15.1621 2.80469C13.5952 2.26807 12.8115 2 12 2C11.1885 2 10.4048 2.26807 8.83789 2.80469L8.26514 3.00098C5.2583 4.02979 3.75488 4.54443 3.37744 5.08252Z"
                fill="#FFFFFF" fillOpacity="1.000000" fillRule="nonzero"/>
        </g>
        <path id="path"
              d="M15.0596 10.4995C15.3354 10.1904 15.3086 9.71631 14.9995 9.44043C14.6904 9.16455 14.2163 9.19141 13.9404 9.50049L10.9287 12.874L10.0596 11.9004C9.78369 11.5913 9.30957 11.5649 9.00049 11.8408C8.69141 12.1162 8.66455 12.5903 8.94043 12.8994L10.3691 14.4995C10.5112 14.6587 10.7148 14.75 10.9287 14.75C11.1421 14.75 11.3457 14.6587 11.4878 14.4995L15.0596 10.4995Z"
              fill="#FFFFFF" fillOpacity="1.000000" fillRule="nonzero"/>
      </g>
    </svg>
  );
}

