import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import MainLayout from "@/layouts/MainLayout";
import HomePage from "@/pages/HomePage";
import DashboardPage from "@/pages/DashboardPage";
import SimpleLayout from "@/layouts/SimpleLayout";
import LoginPage from "@/pages/LoginPage";
import ConfirmPage from "@/pages/ConfirmPage";
import TeamsPage from "@/pages/TeamsPage";
import {PrivateRoute} from "@/components/common/PrivateRoute";
import {NotPrivateRoute} from "@/components/common/NotPrivateRoute";
import Error404Page from "@/pages/Error404Page";
import {AdminRoute} from "@/components/common/AdminRoute";
import SettingsPage from "@/pages/SettingsPage";
import ReportsPage from "@/pages/ReportsPage";
import ArchivePage from "@/pages/ArchivePage";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<PrivateRoute/>}>
          <Route path="/*" element={<MainLayout/>}>
            <Route index element={<DashboardPage/>}/>
            <Route path="reports" element={<ReportsPage/>}/>
            <Route path="settings" element={<SettingsPage/>}/>
            <Route path="tasks/archive" element={<ArchivePage/>} />
            <Route path="teams" element={<TeamsPage/>}/>
            <Route path="*" element={<Error404Page/>}/>
            <Route path="*" element={<AdminRoute/>}>
            </Route>
          </Route>
        </Route>
        <Route path="/*" element={<NotPrivateRoute/>}>
          <Route path="/*" element={<SimpleLayout/>}>
            <Route path="login" element={<LoginPage/>}/>
            <Route path="confirm/:id/:hash" element={<ConfirmPage/>}/>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
