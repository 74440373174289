import React from "react";

type Props = {};

export default function Close({ }: Props) {
    return (
        <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink">
            <desc>
                Created with Pixso.
            </desc>
            <defs>
                <clipPath id="clip1_871">
                    <rect id="Variant=Close" width="24.000000" height="24.000000" fill="white" fill-opacity="0"/>
                </clipPath>
            </defs>
            <g clip-path="url(#clip1_871)">
                <path id="Vector 1" d="M16.61 9.22C17.12 8.71 17.12 7.89 16.61 7.38C16.1 6.87 15.28 6.87 14.77 7.38L12 10.15L9.22 7.38C8.71 6.87 7.89 6.87 7.38 7.38C6.87 7.89 6.87 8.71 7.38 9.22L10.15 12L7.38 14.77C6.87 15.28 6.87 16.1 7.38 16.61C7.89 17.12 8.71 17.12 9.22 16.61L12 13.84L14.77 16.61C15.28 17.12 16.1 17.12 16.61 16.61C17.12 16.1 17.12 15.28 16.61 14.77L13.84 12L16.61 9.22Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero"/>
            </g>
        </svg>

    )
}

