import {DateTime} from "luxon";
import s from "@/pages/DashboardPage/components/KanbanItem/KanbanItem.module.scss";

export const getDeadlineStyle = (date: string | null | undefined) => {
    if (!date) return '';

    const now = DateTime.now();
    const deadline = DateTime.fromISO(date);
    const daysUntilDeadline = deadline.diff(now, 'days').days;

    if (daysUntilDeadline < 0) {
        return s.overdueDeadlineTask;
    } else if (daysUntilDeadline <= 3) {
        return s.warningDeadlineTask;
    }

    return '';
};