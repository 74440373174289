import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Button from '@/UI/Button';
import { UserDto } from '@/api/users/dto/user.dto';
import { StatusEnum } from '@/api/users/interfaces/status.enum';
import Toasts from '@/utils/Toasts';

import s from './LinkStatus.module.scss';

type LinkStatusProps = {
    user: UserDto;
};

export default function LinkStatus({ user }: LinkStatusProps) {
    const { t } = useTranslation();

    const copyLink = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const link = `${window.location.origin}/confirm/${user.id}/${user.hash}`;
            navigator.clipboard
                .writeText(link)
                .then(() => {
                    Toasts.success(t('alerts.copy.success'));
                })
                .catch(() => {
                    Toasts.error(t('alerts.copy.error'));
                });
        } catch (e) {
            Toasts.error(t('alerts.copy.error'));
        }
    };

    return (
        <div className={s.linkStatus}>
            <p
                className={classNames({
                    [s.linkStatus__text]: true,
                    [s.linkStatus__text__confirmed]:
                        user.status === StatusEnum.CONFIRMED,
                    [s.linkStatus__text__not_confirmed]:
                        user.status === StatusEnum.NOT_CONFIRMED,
                })}
            >
                {t(`user.status.${user.status}`)}
            </p>

            {user.status === StatusEnum.NOT_CONFIRMED && (
                <Button onClick={copyLink} className={s.linkStatus__button}>
                    {t('buttonActions.copyLink')}
                </Button>
            )}
        </div>
    );
}
