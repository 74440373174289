import React from 'react';
import classNames from 'classnames';
import ArrowDown from '@/icons/ArrowDown';
import s from './Pagination.module.scss';

type PaginationProps = {
    allPageNumber: number;
    currentPage: number;
    onPageChange: (newPage: number) => void;
};

export default function Pagination({ allPageNumber, currentPage, onPageChange }: PaginationProps) {
    const handlePrevPage = () => {
        if (currentPage > 1) onPageChange(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < allPageNumber) onPageChange(currentPage + 1);
    };

    const getVisiblePages = () => {
        const visiblePages: (number | string)[] = [1];
        if (currentPage > 4) visiblePages.push('...');

        for (let i = Math.max(2, currentPage - 2); i <= Math.min(allPageNumber - 1, currentPage + 2); i++) {
            visiblePages.push(i);
        }

        if (currentPage < allPageNumber - 3) visiblePages.push('...');
        visiblePages.push(allPageNumber);

        return visiblePages;
    };


    return (
        <div className={s.pagination}>
            <button
                className={classNames(s.pagination__button, s.pagination__prev_button)}
                onClick={handlePrevPage}
                disabled={currentPage <= 1}
            >
                <ArrowDown width={16} hanging={16} />
            </button>

            {getVisiblePages().map((elem, index) => (
                <button
                    key={index}
                    className={classNames(s.pagination__button, { [s.active]: currentPage === elem })}
                    disabled={typeof elem === 'string'}
                    onClick={() => typeof elem === 'number' && onPageChange(elem)}
                >
                    {elem}
                </button>
            ))}

            <button
                className={classNames(s.pagination__button, s.pagination__next_button)}
                onClick={handleNextPage}
                disabled={currentPage >= allPageNumber}
            >
                <ArrowDown width={16} hanging={16} />
            </button>
        </div>
    );
}
