import Api from "@/api/Api";
import {UserDto} from "@/api/users/dto/user.dto";
import {UpdateDataUsersDto} from "@/api/users/dto/update.user.dto";
import {ConfirmedUserDto} from "@/api/users/dto/confirmed.user.dto";
import {CreateUserDto} from "@/api/users/dto/create.user.dto";

class UsersMethods extends Api {
    async create(body: CreateUserDto) {
        const {data} = await this.request<UserDto, CreateUserDto>(`/users`, {
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async get() {
        const {data} = await this.request<UserDto[], null>('/users', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async getById(id: number) {
        const {data} = await this.request<UserDto, null>(`/users/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async updateById(id: number, body: UpdateDataUsersDto) {
        const {data} = await this.request<UserDto, UpdateDataUsersDto>(`/users/${id}`, {
            method: 'PUT',
            data: body,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async removeById(id: number) {
        const {data} = await this.request<void, null>(`/users/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }

    async confirmById(id: number, body: ConfirmedUserDto) {
        const {data} = await this.request<void, ConfirmedUserDto>(`/users/${id}/confirmed`, {
            method: 'POST',
            data: body,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.token}`
            }
        })

        return data
    }
}

export default new UsersMethods()