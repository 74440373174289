import React from "react";

export default function ArrowDown(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12 16C11.6287 16 11.2726 15.8597 11.0099 15.6096L5.40992 10.2763C4.86336 9.7552 4.86336 8.91147 5.40992 8.3904C5.95648 7.86987 6.84296 7.86987 7.39008 8.3904L12 12.7808L16.6099 8.3904C17.157 7.86987 18.043 7.86987 18.5901 8.3904C19.1366 8.91093 19.1366 9.7552 18.5901 10.2763L12.9901 15.6096C12.7274 15.8597 12.3713 16 12 16Z" fill="white" />
        </svg>
    );
}

