import {useContext} from "react";
import {Outlet} from "react-router";
import {AuthContext} from "@/providers/AuthProvider";
import {useAppSelector} from "@hooks/store";
import {RightsEnum} from "@/api/users/interfaces/rights.enum";
import Error404Page from "@/pages/Error404Page";

export const AdminRoute = () => {
    const {isAuthenticated} = useContext(AuthContext);
    const profile = useAppSelector((state) => state.profile.profile)

    return (isAuthenticated && profile && profile.rights === RightsEnum.ADMIN ? <Outlet/> : <Error404Page/>);
}