import React, {ReactNode} from 'react';
import s from './TableColumn.module.scss';
import classNames from "classnames";
import Ripple from "@/components/Ripple";

interface TableColumnProps {
    children?: ReactNode;
    isClickable?: boolean
    onClick?: () => void,
    position?: 'flex-end' | 'flex-start'
    title?: string
}

function TableColumn({children, title, isClickable = false, onClick, position = 'flex-start'}: TableColumnProps) {
    return (
        <>
            <div title={title} className={classNames({
                [s.column]: true,
                [s.columnActive]: isClickable,
            })} onClick={onClick} style={{
                justifyContent: position
            }} tabIndex={isClickable ? 0: undefined}>
                {children}
                {isClickable && <Ripple duration={850} color="#ffffff"/>}
            </div>
        </>
    );

}

export default TableColumn;
